import { environment } from '../config/environment'

import { Refund } from './refund'
import { Notify } from './notify'
import { SponsorProduct } from './sponsorProduct'
import { LineCoupon } from './lineCoupon'
import { Tag } from './tag'
import { Form } from './form'

const Request = {
  get: (url) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'GET',
      headers: {
        // lineid: COOKIE.get('lineId'),
        lineid: "Uf6881c2699a9df6da6b9f5bb4784f0d5"
      },
    })
      .then(response => response)
  },
  post: (url, body) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        // lineid: COOKIE.get('lineId'),
        lineid: "Uf6881c2699a9df6da6b9f5bb4784f0d5"
      },
      body: JSON.stringify(body),
    })
      .then(response => response)
  },
  put: (url, body) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        // lineid: COOKIE.get('lineId'),
        lineid: "Uf6881c2699a9df6da6b9f5bb4784f0d5"
      },
      body: JSON.stringify(body),
    })
      .then(response => response)
  },
  delete: (url, body) => {
    return fetch(`${environment.modelServiceUrl}${url}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        // lineid: COOKIE.get('lineId'),
        lineid: "Uf6881c2699a9df6da6b9f5bb4784f0d5"
      },
      body: JSON.stringify(body),
    })
      .then(response => response)
  }
}

export default {
  Request,
  Refund,
  Notify,
  SponsorProduct,
  LineCoupon,
  Tag,
  Form,
}