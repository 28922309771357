import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import List from '../../component/LineCoupon/List'
import agent from '../../agent/agent'

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Edit } from '@material-ui/icons';

class ListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: true,
      tableData: [],
      tableColumns: ["優惠券名稱", "UTM Campaign", "時間戳", ''],
      tableOptions: {
        customToolbar: () => {
          return (
            <Tooltip title="New Coupon">
              <IconButton component={Link} to="/line-coupon/new">
                <AddIcon />
              </IconButton>
            </Tooltip>
          )
        },
        selectableRows: "none"
      },
      editId: null,
    }
    this.getCoupons = this.getCoupons.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  componentDidMount() {
    this.getCoupons()
  }

  async getCoupons() {
    const result = await agent.LineCoupon.getCoupons()
    if (result.status === 200) {
      const resultJson = await result.json()
      const tableData = resultJson.map(coupon => {
        const title = coupon.title
        const utmCampaign = coupon.utmCampaign
        const timestamp = dayjs(coupon.timestamp).format("YYYY-MM-DD HH:mm")
        const tools = <IconButton onClick={() => this.handleEdit(coupon.id)}><Edit /></IconButton>
        return [title, utmCampaign, timestamp, tools]
      })
      this.setState({ tableData, isWaiting: false })
    } else {
      alert("取得 Coupon 發生錯誤")
      this.setState({ tableData: [], isWaiting: false })
    }
  }

  handleEdit(editId) {
    this.setState({ editId })
  }

  render() {
    if (this.state.editId) {
      return <Redirect push to={`/line-coupon/edit?id=${this.state.editId}`} />
    }

    return (
      <List
        isWaiting={this.state.isWaiting}
        tableData={this.state.tableData}
        tableColumns={this.state.tableColumns}
        tableOptions={this.state.tableOptions}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default (connect(mapStateToProps, mapDispatchToProps)(ListContainer))