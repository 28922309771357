import React from 'react'
import { connect } from 'react-redux'

import Report from '../../component/Tag/Report'
import { getTagStatistic, getInactiveMemberStatistic } from '../../action/tag'

class ReportContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      inactiveData: null,
    }
    this.setData = this.setData.bind(this)
    this.setInactiveData = this.setInactiveData.bind(this)
  }

  componentWillMount() {
    if (!this.props.statistic) {
      this.props.getTagStatistic()
      this.props.getInactiveMemberStatistic()
    }
  }

  componentDidMount() {
    this.setData()
    this.setInactiveData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.statistic !== this.props.statistic) {
      this.setData()
    }

    if (prevProps.inactiveStatistic !== this.props.inactiveStatistic) {
      this.setInactiveData()
    }
  }

  setData() {
    const { statistic } = this.props
    if (statistic) {
      const data = []
      for (const tag of statistic) {
        const { name, amt, uniqueAmt } = tag
        data.push({
          name,
          "總貼標次數": amt,
          "不重複貼標次數": uniqueAmt,
        })
      }
      this.setState({ data })
    }
  }

  setInactiveData() {
    const { inactiveStatistic } = this.props
    if (inactiveStatistic) {
      this.setState({ inactiveData: inactiveStatistic })
    }
  }

  render() {
    return (
      <Report
        data={this.state.data}
        inactiveData={this.state.inactiveData}
      />
    )
  }
}

const mapStateToProps = state => ({
  statistic: state.tag.statistic,
  inactiveStatistic: state.tag.inactiveStatistic,
})

const mapDispatchToProps = dispatch => ({
  getTagStatistic: () => {
    dispatch(getTagStatistic())
  },
  getInactiveMemberStatistic: () => {
    dispatch(getInactiveMemberStatistic())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer)
