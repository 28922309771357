import agent from '../agent/agent'

export const RECEIVE_TAG_STATISTIC = "RECEIVE_TAG_STATISTIC"
export const RECEIVE_INACTIVE_MEMBER_STATISTIC = "RECEIVE_INACTIVE_MEMBER_STATISTIC"

export const getTagStatistic = () => dispatch => {
    return agent.Tag.statistic()
        .then(result => result.json())
        .then(resultJson => {
            dispatch(receiveTagStatistic(resultJson))
        })
        .catch(error => {
            console.error(error)
            dispatch(receiveTagStatistic([]))
        })
}

const receiveTagStatistic = data => {
    return {
        type: RECEIVE_TAG_STATISTIC,
        data
    }
}

export const getInactiveMemberStatistic = () => dispatch => {
    return agent.Tag.getInactiveStatistic()
        .then(result => result.json())
        .then(resultJson => {
            dispatch(receiveInactiveMemberStatistic(resultJson))
        })
        .catch(error => {
            console.error(error)
            dispatch(receiveInactiveMemberStatistic([]))
        })
}

const receiveInactiveMemberStatistic = data => {
    return {
        type: RECEIVE_INACTIVE_MEMBER_STATISTIC,
        data
    }
}