import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'
import dayjs from 'dayjs'

import AppNew from '../../component/Article/AppNew'

class AppNewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      author: "樂台計畫",
      article: "",
      tags: [],
      timestamp: dayjs().format("YYYY-MM-DD"),
      coverPhoto: null,          // base64
      coverPhotoFile: null,      // blob
      coverPhotoFilepath: null,  // gs path
      isShow: true,
      isWaiting: false,
      isRedirectToList: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleMarkdownChange = this.handleMarkdownChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleFileChange = this.handleFileChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleMarkdownChange(value) {
    this.setState({ article: value })
  }

  handleFileChange(base64, file, fileName) {
    this.setState({ coverPhoto: base64 })
    if (base64) {
      const { firebase: { storage } } = this.props;
      const storageRef = storage().ref()
      const fileRef = storageRef.child(`appArticle/coverPhoto/${fileName}`)
      fileRef.put(file)
        .then(snap => {
          this.setState({ coverPhotoFilepath: snap.metadata.fullPath })
          console.log('upload successful', snap)
        })
        .catch(err => console.error('error uploading file', err))

    }
  }

  async handleSubmit() {
    const article = {
      title: this.state.title,
      author: this.state.author,
      article: this.state.article,
      tags: this.state.tags,
      image: this.state.coverPhotoFilepath,
      isShow: this.state.isShow,
      timestamp: +new Date(this.state.timestamp),
    }
    if (article.image) {
      this.setState({ isWaiting: true })
      await this.props.firestore.add('appArticle', article)
      this.setState({ isRedirectToList: true })
    } else {
      alert("需上傳封面圖片")
    }
  }

  render() {
    if (this.state.isRedirectToList) {
      return <Redirect push to="/app-article" />
    }

    return (
      <AppNew
        title={this.state.title}
        author={this.state.author}
        article={this.state.article}
        coverPhoto={this.state.coverPhoto}
        tags={this.state.tags}
        timestamp={this.state.timestamp}
        isShow={this.state.isShow}
        isWaiting={this.state.isWaiting}

        handleInputChange={this.handleInputChange}
        handleMarkdownChange={this.handleMarkdownChange}
        handleFileChange={this.handleFileChange}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {

    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(AppNewContainer))