import React from 'react'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { Import } from 'mdi-material-ui'

class MessageMenu extends React.Component {
  render() {
    const { anchorEl } = this.props;
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.props.handleClose}
      >
        <MenuItem onClick={this.props.handleClose}>
          <ListItemIcon>
            <Import />
          </ListItemIcon>
          <ListItemText inset primary="匯入訊息" />
        </MenuItem>
        <MenuItem onClick={this.props.handleClose}>
          <ListItemIcon>
            <SaveIcon />
          </ListItemIcon>
          <ListItemText inset primary="保存此訊息" />
        </MenuItem>
        <MenuItem onClick={this.props.removeMessage}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText inset primary="刪除此訊息" />
        </MenuItem>
      </Menu>
    )
  }
}

export default MessageMenu