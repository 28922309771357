import { lineLoginConfig, environment } from "../../config/environment"
const queryString = require('query-string');
const redirectUri = window.location.origin + window.location.pathname

export default class lineAuth {
  constructor(cookies, location, setAuthStatus){
    this.cookies = cookies
    this.location = location
    this.setAuthStatus = setAuthStatus
    this.params = queryString.parse(location.search)
    this.init()
  }

  async init(){
    if(!this.cookies.get("lineId") || !this.cookies.get("isAuth")){
      if(this.params.state !== "authorized") {
        this.lineAuthorization()
      }
      else{
        const result = await this.decodeLineId(this.params.code, redirectUri)
        const lineId = result.message
        const isAuth = result.isAuth
        if(this.isValidLineId(lineId) && isAuth){
          this.cookies.set("lineId", lineId)
          this.cookies.set("isAuth", isAuth)
          this.setAuthStatus(isAuth)
        }else{
          this.catchLineIdFail()
        }
      }
    }
    else{
      const lineId = this.cookies.get("lineId")
      const isAuth = this.cookies.get("isAuth")
      if(this.isValidLineId(lineId) && isAuth){
        this.setAuthStatus(isAuth)
      }else{
        this.catchLineIdFail()
      }
    }
  }

  async decodeLineId(lineCode, redirectUri) {
    return new Promise((resolve, reject) => {
      return fetch(environment.modelServiceUrl + "/lineAuth/cms", {
        headers: {
          "Content-Type": "application/json"
        },
        method: 'POST',
        body: JSON.stringify({
          code: lineCode,
          redirectUri: redirectUri,
        })
      })
      .then(response => response.json())
      .then(responseJson => {
        resolve(responseJson)
      })
    })
  }

  lineAuthorization(){
    var url = 'https://access.line.me/oauth2/v2.1/authorize?';
    url += 'response_type=code';
    url += `&client_id=${lineLoginConfig.client_id}`;
    url += `&redirect_uri=${redirectUri}`;
    url += `&state=authorized`;
    url += '&scope=openid%20profile';
    window.location.href = url;
  }

  isValidLineId(lineId){
    const regexp = new RegExp('^U[0-9a-f]{32}$')
    return regexp.test(lineId)
  }

  catchLineIdFail(){
    this.cookies.remove("lineId")
    alert("LINE 系統授權失敗，即將幫你排除💪")
    window.location.href = redirectUri
  }

}