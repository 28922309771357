import React from 'react'
import MUIDataTable from "mui-datatables";
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

export default class MemberTable extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(selectedRows, displayData, setSelectedRows) {
    const index = selectedRows[`data`][0][`index`]
    const data = displayData[index][`data`]
    const lineId = data[0]
    const name = data[1]
    const email = data[3]
    const schoolName = data[5]
    this.props.setTargetMember(lineId, name, email, schoolName)
  }

  render() {
    const { isMemberModalOpen } = this.props;

    return (
      <Dialog open={isMemberModalOpen}
        onClose={() => { this.props.setModalState('isMemberModalOpen', false) }}
        aria-labelledby="simple-dialog-title"
        maxWidth={'lg'} fullWidth
      >
        {
          this.props.tableData &&
          <MUIDataTable
            title={"會員列表"}
            data={this.props.tableData}
            columns={["LINE ID", "姓名", "電話", "Email", "學制", "學校"]}
            options={{
              print: false,
              download: false,
              viewColumns: false,
              selectableRows: 'single',
              customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                return (
                  <div className="table-toolbar-btn-group">
                    <Button variant="contained" color="primary"
                      onClick={() => this.handleSubmit(selectedRows, displayData, setSelectedRows)}
                    >
                      選擇此用戶
                  </Button>
                  </div>
                )
              },
            }}
          />
        }
      </Dialog>
    )
  }
}