import agent from './agent'

export const Tag = {
    statistic: async () => {
        return new Promise((resolve, reject) => {
            return agent.Request.get(`/tag/statistic`)
                .then(response => resolve(response))
        })
    },
    getInactiveStatistic: async () => {
        return new Promise((resolve, reject) => {
            return agent.Request.get(`/tag/statistic/inactive`)
                .then(response => resolve(response))
        })
    }
}