import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect, Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import dayjs from 'dayjs'

import List from '../../component/Tag/List'
import Snackbar from '../../utils/component/Snackbar'

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Edit } from '@material-ui/icons';

class ListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: true,
      tableData: [],
      tableColumns: ["標籤 ID", "標籤名稱", "時間戳", ''],
      tableOptions: {
        customToolbar: () => {
          return (
            <Tooltip title="New Tag">
              <IconButton component={Link} to="/tag/new">
                <AddIcon />
              </IconButton>
            </Tooltip>
          )
        },
        selectableRows: "none"
      },
      editId: null,
      isShowSnackbar: false,
    }
    this.getTags = this.getTags.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
  }

  componentDidMount() {
    this.getTags()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      this.getTags()
    }
  }

  getTags() {
    if (this.props.tags) {
      const tableData = this.props.tags.map(tag => {
        const id =
          <CopyToClipboard text={tag.id} onCopy={this.handleCopy}>
            <span className="copyable">{tag.id}</span>
          </CopyToClipboard>

        const name = tag.name
        const timestamp = dayjs(tag.timestamp).format("YYYY-MM-DD HH:mm")
        const tools = <IconButton onClick={() => this.handleEdit(tag.id)}><Edit /></IconButton>

        return [id, name, timestamp, tools]
      })
      this.setState({ tableData, isWaiting: false })
    }
  }

  handleEdit(editId) {
    this.setState({ editId })
  }

  handleCopy(text, result) {
    this.setState({ isShowSnackbar: !!result }, () => {
      setTimeout(() => { this.setState({ isShowSnackbar: false }) }, 1000)
    })
  }

  render() {
    if (this.state.editId) {
      return <Redirect push to={`/tag/edit?id=${this.state.editId}`} />
    }

    return (
      <React.Fragment>
        <List
          isWaiting={this.state.isWaiting}
          tableData={this.state.tableData}
          tableColumns={this.state.tableColumns}
          tableOptions={this.state.tableOptions}
        />
        <Snackbar
          open={this.state.isShowSnackbar}
          message="已複製"
          variant="success"
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([
    'tag' // { path: '/route...' }
  ]),
  connect((state) => {
    return {
      tags: state.firestore.ordered.tag
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(ListContainer))