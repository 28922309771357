import {
    RECEIVE_TAG_STATISTIC,
    RECEIVE_INACTIVE_MEMBER_STATISTIC,
} from '../action/tag'

const initialState = {
    statistic: null,
    inactiveStatistic: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_TAG_STATISTIC:
            return {
                ...state,
                statistic: action.data
            }
        case RECEIVE_INACTIVE_MEMBER_STATISTIC:
            return {
                ...state,
                inactiveStatistic: action.data
            }
        default:
            return state
    }
}