import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'

import Refund from '../../component/Refund/Refund'
import { GROUP } from '../../config/environment'
import agent from '../../agent/agent'

import Button from '@material-ui/core/Button';

class RefundContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isWaiting: true,
      tableData: [],
      tableColumns: ["姓名", "屆數", "組別", "歌曲", "銀行代碼", "退款帳號", "退款金額", "狀態"],
      tableOptions: {
        print: false,
        download: false,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows)  => {
          return (
            <div className="table-toolbar-btn-group">
              <Button variant="contained" color="primary"
                onClick={() => this.handleRefund(selectedRows, displayData, setSelectedRows)}
              >
                發佈退款通知
              </Button>
            </div>
          )
        },
        isRowSelectable: dataIndex => {
          return !this.state.refundInfo[dataIndex].isRefund
        }
      },
      refundInfo: null,     // 全部資料
      schoolId: null,
      isSnackbarOpen: false,
    }
    this.getRefundList = this.getRefundList.bind(this)
    this.setTableData = this.setTableData.bind(this)
    this.handleRefund = this.handleRefund.bind(this)
  }

  componentWillMount(){
    this.getRefundList()
  }

  async getRefundList(){
    const pathname = this.props.location.pathname.split('/')
    const schoolId = pathname[2]
    const result = await agent.Refund.getList(schoolId)
    if(result.status === 200){
      const resultJson = await result.json()
      this.setState({
        refundInfo: resultJson,
        isSnackbarOpen: false,
        schoolId,
      }, () => {
        this.setTableData()
      })
    }else {
      alert("取得退款列表時發生錯誤")
    }
  }

  setTableData(){
    const tableData = this.state.refundInfo.map(info => {
      const name = info.name
      const group = GROUP[info.group]
      const song = info.song
      const bankCode = info.bankCode
      const account = info.account
      const refundAmt = info.refundAmt
      const isRefund = info.isRefund ? "已退費" : "尚未退費"
      const period = info.period
      return [name, period, group, song, bankCode, account, refundAmt, isRefund]
    })
    this.setState({ tableData, isWaiting: false })
  }

  async handleRefund(selectedRows, displayData, setSelectedRows){
    const confirm = window.confirm("確定發佈退款通知嗎？")
    if(confirm){
      const forms = selectedRows.data.map(row => {
        const index = row.index
        const info = this.state.refundInfo[index]
        return ({
          lineId: info.lineId,
          formId: info.id,
          group: info.group,
          refundAmt: info.refundAmt
        })
      })
      const body = { forms, schoolId: this.state.schoolId }

      this.setState({ isWaiting: true })

      const result = await agent.Refund.setRefunded(body)
      if(result.status === 204){
        this.setState({ isSnackbarOpen: true })
        this.getRefundList()
      }else{
        alert("系統錯誤：通知退款時發生錯誤，幫我跟ㄎㄑ說")
      }
    }
  }

  render(){
    return (
      <Refund
        isWaiting={this.state.isWaiting}
        isSnackbarOpen={this.state.isSnackbarOpen}
        tableData={this.state.tableData}
        tableColumns={this.state.tableColumns}
        tableOptions={this.state.tableOptions}
        refundInfo={this.state.refundInfo}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {

    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(RefundContainer))