import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Close } from '@material-ui/icons';

import lrz from 'lrz'

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.handleSubmit = this.handleSubmit.bind(this)

    this.coverPhotoRef = React.createRef()
    this.handleFileChange = this.handleFileChange.bind(this)
    this.hanldeClickUploadBtn = this.hanldeClickUploadBtn.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  hanldeClickUploadBtn() {
    document.getElementById("coverPhoto").click()
  }

  handleFileChange() {
    const file = this.coverPhotoRef.current.files[0]
    lrz(file).then(result => {
      const reader = new FileReader()
      reader.readAsDataURL(result.file)
      reader.onload = () => {
        const base64 = reader.result
        const file = result.file
        const fileName = result.origin.name
        this.props.handleFileChange(base64, file, fileName)
      }
      reader.onerror = (err) => {
        console.error(err)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="container-fluid sponsor-product-new">
        <h5>編輯廣告</h5>
        <Paper className="paper">
          {
            this.props.isWaiting &&
            <CircularProgress className="init-loader" />
          }
          {
            !this.props.isWaiting &&
            <form onSubmit={this.handleSubmit} className="row">
              <div className="col-lg-6 col-12">
                <TextField fullWidth required
                  id="title" name="title"
                  label="標題"
                  className={classes.textField}
                  value={this.props.title}
                  onChange={this.props.handleInputChange}
                  margin="normal"
                />
              </div>
              <div className="col-lg-6 col-12">
                <FormControl className={classes.formControl} fullWidth required>
                  <InputLabel htmlFor="type">類型</InputLabel>
                  <Select
                    name="type"
                    value={this.props.type}
                    inputProps={{
                      name: 'type',
                      id: 'type',
                    }}
                    disabled
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      this.props.types &&
                      this.props.types.map(type => {
                        return <MenuItem key={`type${type.value}`} value={type.value}>{type.label}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </div>
              {
                this.props.type === 2 &&
                <div className="col-9">
                  <TextField fullWidth
                    id="videoLink" name="videoLink" required
                    label="影片連結"
                    className={classes.textField}
                    value={this.props.videoLink}
                    onChange={this.props.handleInputChange}
                    margin="normal"
                  />
                </div>
              }
              {
                this.props.type === 2 &&
                <div className="col-3">
                  <TextField fullWidth type="number"
                    id="minPlayingSecond" name="minPlayingSecond"
                    label="最少觀看秒數"
                    className={classes.textField}
                    value={this.props.minPlayingSecond}
                    onChange={this.props.handleInputChange}
                    margin="normal"
                  />
                </div>
              }
              <div className="col-lg-9 col-12">
                <TextField fullWidth required
                  id="description"
                  name="description"
                  className={classes.textField}
                  label="內容"
                  value={this.props.description}
                  onChange={this.props.handleInputChange}
                  multiline
                  rows="5"
                  rowsMax="12"
                  margin="normal"
                />
              </div>
              <div className="col-lg-3 col-12">
                {
                  !this.props.coverPhoto &&
                  <div className="btn-upload-wrapper">
                    <input id="coverPhoto" type="file" accept="image/*" className="d-none"
                      onChange={this.handleFileChange} ref={this.coverPhotoRef}
                    />
                    <Button variant="contained" color="primary" className={classes.button} fullWidth
                      onClick={this.hanldeClickUploadBtn}
                    >
                      封面照片&nbsp;
                    <CloudUploadIcon className={classes.rightIcon} />
                    </Button>
                  </div>
                }
                {
                  this.props.coverPhoto &&
                  <div className="mde-wrapper cover-photo">
                    <div className="tool-bar" onClick={() => this.props.handleFileChange(null)}>
                      <Close className="icon" />
                      <span className="text">移除</span>
                    </div>
                    <img alt="封面圖" src={this.props.coverPhoto} className="img-fluid" />
                  </div>
                }
              </div>
              <div className="col-12">
                <Button type="submit" variant="contained" color="primary" className={classes.button}
                  disabled={this.props.isWaitingUpdate}
                >
                  {!this.props.isWaitingUpdate && "更新"}
                  {this.props.isWaitingUpdate && <CircularProgress className={classes.progress} size={20} />}
                </Button>
                <Button className={classes.button} disabled={this.props.isWaitingUpdate} component={Link} to="/sponsor-product">
                  返回
                </Button>
              </div>
            </form>

          }
        </Paper>
      </div>
    )
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Edit);