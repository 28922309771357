import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import Connect from '../../component/SponsorProductConnect/Connect'
import agent from '../../agent/agent'

const isEmpty = require("lodash.isempty")
class ConnectContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRedirectToList: false,
      isWaiting: true,
      targetSchoolId: null,
      targetSchool: null,
      lotteryInfo: [],

      isShowSnackbar: false,
      errorMsg: null,

      participantProductsSelected: '',
      popularProductsSelected: '',
      lotteryProductsSelected: [],
    }
    this.getTargetSchool = this.getTargetSchool.bind(this)
    this.getTargetSchoolLottery = this.getTargetSchoolLottery.bind(this)
    this.setTargetSchool = this.setTargetSchool.bind(this)
    this.setLotteryInfo = this.setLotteryInfo.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleLotteryConnectChange = this.handleLotteryConnectChange.bind(this)

    this.closeSnackbar = this.closeSnackbar.bind(this)
  }

  componentWillMount() {
    this.getTargetSchool()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolInfo !== this.props.schoolInfo) {
      this.setTargetSchool()
    }
    if (prevProps.lotteryInfo !== this.props.lotteryInfo) {
      this.setLotteryInfo()
    }
  }

  async getTargetSchool() {
    const pathname = this.props.location.pathname.split('/')
    const targetSchoolId = pathname[3]
    if (targetSchoolId) {
      this.setState({ targetSchoolId })
      this.props.firestore.get({
        collection: 'school',
        where: ['id', '==', targetSchoolId]
      })
    } else {
      this.setState({ isRedirectToList: true })
    }
  }

  getTargetSchoolLottery() {
    const schoolId = this.state.targetSchool.id
    const period = this.state.targetSchool.period
    this.props.firestore.get({
      collection: 'lottery',
      doc: schoolId,
      subcollections: [{
        collection: String(period)
      }]
    })
  }

  setTargetSchool() {
    const targetSchool = this.props.schoolInfo.find(school => school.id === this.state.targetSchoolId)
    if (!isEmpty(targetSchool)) {
      const popularProductsSelected = targetSchool.popularVote ?
        targetSchool.popularVote.restrictId ? targetSchool.popularVote.restrictId : ''
        :
        ''
      const participantProductsSelected = targetSchool.restrictId ? targetSchool.restrictId : ''
      this.setState({
        targetSchool,
        participantProductsSelected,
        popularProductsSelected
      }, () => {
        this.getTargetSchoolLottery()
      })
    } else {
      this.setState({ isRedirectToList: true })
    }
  }

  setLotteryInfo() {
    const targetSchoolLottery = this.props.lotteryInfo.find(lottery => lottery.id === this.state.targetSchoolId)
    const targetPeriodLottery = targetSchoolLottery[this.state.targetSchool.period]
    const lotteryProductsSelected = targetPeriodLottery ?
      targetPeriodLottery.map(lottery => {
        return ({
          id: lottery.id,
          value: lottery.restrictId ? lottery.restrictId : ''
        })
      })
      :
      []

    this.setState({
      lotteryInfo: targetPeriodLottery,
      lotteryProductsSelected,
      isWaiting: false,
    })
  }

  async handleSubmit() {
    this.setState({
      isWaiting: true,
      isShowSnackbar: false,
      errorMsg: null
    })

    const lotteries = this.state.lotteryProductsSelected.map(product => {
      return product.value === '' ? { id: product.id, value: null } : product
    })

    const body = {
      schoolId: this.state.targetSchoolId,
      period: this.state.targetSchool.period,
      participant: this.state.participantProductsSelected === '' ? null : this.state.participantProductsSelected,
      popularVote: this.state.popularProductsSelected === '' ? null : this.state.popularProductsSelected,
      lottery: lotteries,
    }

    await agent.SponsorProduct.connect(body)
      .then(result => {
        switch (result.status) {
          case 204:
            this.setState({ isShowSnackbar: true })
            break;
          default:
            this.setState({ errorMsg: '廣告連結失敗！' })
            break;
        }
      })

    this.setState({ isWaiting: false })
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleLotteryConnectChange(event) {
    const lotteryProductsSelected = this.state.lotteryProductsSelected.map(product => {
      if (product.id === event.target.name) {
        product.value = event.target.value
      }
      return product
    })
    this.setState({ lotteryProductsSelected })
  }

  closeSnackbar() {
    this.setState({ isShowSnackbar: false })
  }

  render() {
    if (this.state.isRedirectToList) {
      return <Redirect push to={`/sponsor-product/connect`} />
    }

    return (
      <Connect
        isWaiting={this.state.isWaiting}
        targetSchool={this.state.targetSchool}
        lotteryInfo={this.state.lotteryInfo}

        participantProducts={this.props.participantProducts}
        screenshotProducts={this.props.screenshotProducts}
        sponsorProducts={this.props.sponsorProducts}

        participantProductsSelected={this.state.participantProductsSelected}
        lotteryProductsSelected={this.state.lotteryProductsSelected}
        popularProductsSelected={this.state.popularProductsSelected}

        isShowSnackbar={this.state.isShowSnackbar}
        errorMsg={this.state.errorMsg}
        closeSnackbar={this.closeSnackbar}

        handleSubmit={this.handleSubmit}
        handleInputChange={this.handleInputChange}
        handleLotteryConnectChange={this.handleLotteryConnectChange}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([
    'restrict' // { path: '/route...' }
  ]),
  connect((state) => {
    return {
      schoolInfo: state.firestore.ordered.school,
      lotteryInfo: state.firestore.ordered.lottery,
      sponsorProducts: state.firestore.ordered.restrict,
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(ConnectContainer))