import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import accounting from 'accounting'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  button: {
    marginTop: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

class Report extends PureComponent {
  render() {
    const { classes, data, inactiveData } = this.props;

    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <Paper className={classes.control}>
            <Grid container>
              {
                data ?
                  <Grid item xs={12}>
                    <h5 className="mb-3">標籤統計</h5>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        height={300}
                        data={data}
                        margin={{
                          top: 20, right: 30, left: 20, bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" onClick={this.props.clickXAxis} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="總貼標次數" stackId="a" fill="#8884d8" onClick={this.props.clickData} />
                        <Bar dataKey="不重複貼標次數" stackId="a" fill="#82ca9d" onClick={this.props.clickData} />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                  :
                  <Grid item xs={12}>
                    <CircularProgress className={classes.progress} />
                  </Grid>
              }
              {
                inactiveData &&
                <Grid item xs={4}>
                  <Paper className={classes.control} style={{ marginTop: '16px' }}>
                    <h6>0 活躍用戶</h6>
                    <Typography inline color="primary" style={{ fontSize: '1.9rem', fontWeight: 500 }}>
                      {accounting.formatNumber(inactiveData.length)}
                    </Typography>
                    &nbsp;&nbsp;位
                  </Paper>
                </Grid>
              }
            </Grid>
          </Paper>
        </Grid>
      </Grid>

    );
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Report);