import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import New from '../../component/LineCoupon/New'
import agent from '../../agent/agent'

const cloneDeep = require("lodash.clonedeep")

class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: false,
      isWaitingSubmit: false,
      isRedirectToList: false,

      typeOptions: [
        { label: "優惠券", value: 1, },
        { label: "集點卡", value: 2, },
        { label: "集點卡 QR Code", value: 3, },
      ],
      typeSelected: 1,
      title: "",
      utmCampaign: "",
      originUrl: "",
      tagOptions: [],
      tagSelected: [],
      sources: [],
    }
    this.setTagOptions = this.setTagOptions.bind(this)
    this.removeTag = this.removeTag.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleUTMKeywordChange = this.handleUTMKeywordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.addSource = this.addSource.bind(this)
    this.removeSource = this.removeSource.bind(this)
    this.editSource = this.editSource.bind(this)
    this.toggleSource = this.toggleSource.bind(this)
    
    this.getQRCode = this.getQRCode.bind(this)
    this.getFinalUrl = this.getFinalUrl.bind(this)
  }

  componentDidMount() {
    this.setTagOptions()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.tags !== this.props.tags) {
      this.setTagOptions()
    }
  }

  setTagOptions() {
    if (this.props.tags) {
      const tagOptions = this.props.tags.map(tag => {
        return ({
          label: tag.name,
          value: tag.id,
        })
      })
      this.setState({ tagOptions })
    }
  }

  removeTag(tagId) {
    let tagSelected = cloneDeep(this.state.tagSelected)
    tagSelected = tagSelected.filter(tag => tag.value !== tagId)
    this.setState({ tagSelected })
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  /** For utmCampaign, originUrl field */
  handleUTMKeywordChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const sources = cloneDeep(this.state.sources)
      for (const source of sources) {
        source[`finalUrl`] = this.getFinalUrl(source)
      }
      this.setState({ sources })
    })
  }

  async handleSubmit() {
    this.setState({ isWaitingSubmit: true })
    const body = {
      title: this.state.title,
      utmCampaign: this.state.utmCampaign,
      type: this.state.typeSelected,
      originUrl: this.state.originUrl,
      tags: this.state.tagSelected.map(tag => tag.value),
      source: this.state.sources.map(source => {
        return ({
          utmSource: source.utmSource,
          utmMedium: source.utmMedium,
          utmTerm: source.utmTerm,
          utmContent: source.utmContent,
          finalUrl: source.finalUrl,
          remark: source.remark,
        })
      })
    }
    const result = await agent.LineCoupon.create(body)
    const isSuccess = result.status === 204
    this.setState({
      isRedirectToList: isSuccess,
      isWaitingSubmit: false,
    })
    if (!isSuccess) alert("新增失敗")
  }

  addSource() {
    const sources = cloneDeep(this.state.sources)
    sources.push({
      utmSource: "",
      utmMedium: "",
      utmTerm: "",
      utmContent: "",
      finalUrl: "",
      QRCode: "",
      remark: "",
      isOpen: true,
      isShowQRCode: false,
    })
    sources[sources.length - 1][`finalUrl`] = this.getFinalUrl(sources[sources.length - 1])
    this.setState({ sources })
  }

  removeSource(index) {
    const sources = cloneDeep(this.state.sources)
    sources.splice(index, 1)
    this.setState({ sources })
  }

  editSource(index, event) {
    const sources = cloneDeep(this.state.sources)
    sources[index][event.target.name] = event.target.value

    const finalUrl = this.getFinalUrl(sources[index])
    sources[index][`finalUrl`] = finalUrl

    this.setState({ sources })
  }

  toggleSource(index) {
    let sources = cloneDeep(this.state.sources)
    sources[index].isOpen = !sources[index].isOpen
    this.setState({ sources })
  }

  getFinalUrl(targetSource) {
    const source = cloneDeep(targetSource)
    let finalUrl = `https://app.mcip.ml/line-coupon?`

    if (this.state.utmCampaign === "") return ""

    if (this.state.utmCampaign !== "")
      finalUrl = finalUrl + `utm_campaign=${this.state.utmCampaign}`

    if (source.utmSource !== "")
      finalUrl = finalUrl + `&utm_source=${source.utmSource}`

    if (source.utmMedium !== "")
      finalUrl = finalUrl + `&utm_medium=${source.utmMedium}`

    if (source.utmTerm !== "")
      finalUrl = finalUrl + `&utm_term=${source.utmTerm}`

    if (source.utmContent !== "")
      finalUrl = finalUrl + `&utm_content=${source.utmContent}`

    if (this.state.originUrl !== "")
      finalUrl = finalUrl + `&redirect_url=${this.state.originUrl}`

    return finalUrl
  }

  getQRCode(index) {
    const sources = cloneDeep(this.state.sources)
    const isFailGetQRCode = this.state.utmCampaign === "" ||
      this.state.originUrl === "" ||
      sources[index].utmMedium === "" ||
      sources[index].utmSource === ""
    if (isFailGetQRCode) {
      return alert("請檢查 Coupon 連結與三個 UTM 欄位都已填入")
    } else {
      sources[index][`isShowQRCode`] = true
      this.setState({ sources })
    }
  }

  render() {
    if(this.state.isRedirectToList){
      return <Redirect push to="/line-coupon" />
    }

    return (
      <New
        typeOptions={this.state.typeOptions}
        typeSelected={this.state.typeSelected}
        title={this.state.title}
        utmCampaign={this.state.utmCampaign}
        originUrl={this.state.originUrl}
        tagOptions={this.state.tagOptions}
        tagSelected={this.state.tagSelected}
        sources={this.state.sources}
        isWaitingSubmit={this.state.isWaitingSubmit}

        handleInputChange={this.handleInputChange}
        handleUTMKeywordChange={this.handleUTMKeywordChange}
        removeTag={this.removeTag}

        addSource={this.addSource}
        removeSource={this.removeSource}
        editSource={this.editSource}
        toggleSource={this.toggleSource}

        getQRCode={this.getQRCode}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

export default compose(
  firestoreConnect([
    "tag",
  ]),
  connect((state) => {
    return {
      tags: state.firestore.ordered.tag
    }
  })
)(NewContainer)