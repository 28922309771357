import React from 'react'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const isEmpty = require('lodash.isempty')

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class List extends React.Component {
  constructor(props){
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e){
    e.preventDefault()
    this.props.handleSubmit()
  }

  render(){
    const { classes } = this.props;

    return (
      <div className="container-fluid refund-list">
        <h5>退費管理</h5>
        <Paper className="paper">
          {
            this.props.isWaiting &&
            <CircularProgress className={classes.progress} />
          }
          {
            !this.props.isWaiting &&
            <form onSubmit={this.handleSubmit} className={classes.root}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="targetSchoolId">請選擇院校</InputLabel>
                <Select
                  value={this.props.targetSchoolId}
                  onChange={this.props.handleInputChange}
                  inputProps={{
                    name: 'targetSchoolId',
                    id: 'targetSchoolId',
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    !isEmpty(this.props.schoolInfo) &&
                    this.props.schoolInfo.map(school => {
                      return <MenuItem value={school.id} key={school.id}>{school.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <div className="btn-group">
                <Button type="submit" variant="contained" color="primary">
                  查詢
                </Button>
              </div>
            </form>
          }
        </Paper>
      </div>
    )
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);
