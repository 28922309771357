import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

import { reactReduxFirebase } from 'react-redux-firebase'
import { reduxFirestore } from 'redux-firestore' // <- needed if using firestore
import firebase from 'firebase/app'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

import { MuiThemeProvider } from '@material-ui/core/styles';
import mateiralUITheme from './mateiralUITheme'

import 'bootstrap/dist/css/bootstrap.min.css';
import './utils/css/style.sass'

import reducer from './reducer'
import App from './App';

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp({
  apiKey: "AIzaSyDuDeYbEy4fl2EghgFFCs0da5yc44oq2OM",
  authDomain: "mc-integration-platform.firebaseapp.com",
  databaseURL: "https://mc-integration-platform.firebaseio.com",
  projectId: "mc-integration-platform",
  storageBucket: "mc-integration-platform.appspot.com",
  messagingSenderId: "139181590761"
})
// Initialize Cloud Firestore through Firebase
firebase.firestore().settings({ timestampsInSnapshots: true })

const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig), // firebase instance as first argument
  reduxFirestore(firebase), // firebase instance as first argument, rfConfig as optional second
)(createStore)

const middleware = [ thunk ]

if(process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}

const store = createStoreWithFirebase(
// const store = createStore(
  reducer,
  applyMiddleware(...middleware)
)

ReactDOM.render(
  <CookiesProvider>
    <MuiThemeProvider theme={mateiralUITheme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MuiThemeProvider>
  </CookiesProvider>,
  document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
