import React from 'react'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
})

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <h5>編輯標籤</h5>
          <Paper className="paper">
            {
              this.props.isWaiting &&
              <CircularProgress className="init-loader" />
            }
            {
              !this.props.isWaiting &&
              <form onSubmit={this.handleSubmit}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <TextField
                      id="name" name="name" required
                      label="標籤名稱"
                      className={classes.formControl}
                      value={this.props.name}
                      onChange={this.props.handleInputChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      id="lineTextRef" name="lineTextRef"
                      label="LINE API Text 關聯字"
                      className={classes.formControl}
                      value={this.props.lineTextRef}
                      onChange={this.props.handleInputChange}
                      margin="normal"
                      fullWidth
                      helperText="用於 LINE API 中的文字訊息格式，若文字均符合則標記，為非 postback 方式的解套方法"
                      />
                    <TextField
                      id="remark" name="remark" required
                      label="備註"
                      multiline
                      rows="4"
                      className={classes.formControl}
                      value={this.props.remark}
                      onChange={this.props.handleInputChange}
                      margin="normal"
                      fullWidth
                    />
                    <div className={classes.formControl}>
                      <Button type="submit" variant="contained" color="primary" className={classes.button}
                        disabled={this.props.isWaitingUpdate}
                      >
                        {!this.props.isWaitingUpdate && "更新"}
                        {this.props.isWaitingUpdate && <CircularProgress className={classes.progress} size={20} />}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            }
          </Paper>
        </Grid>
      </Grid >
    )
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Edit);