import React from 'react'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

class CardMessage extends React.Component {
  render() {
    const { classes, index, cardImageUri, cardTitle, cardDescription, cardButtons } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <TextField required fullWidth
            name="cardTitle" id="cardTitle"
            label="標題"
            className={classes.formControl}
            value={cardTitle}
            onChange={(e) => this.props.handleMessageInputChange(e, index)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth required
            name="cardImageUri" id="cardImageUri"
            label="封面圖片網址"
            className={classes.formControl}
            value={cardImageUri}
            onChange={(e) => this.props.handleMessageInputChange(e, index)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="文字內容" required
            name="cardDescription" id="cardDescription" className={classes.formControl}
            multiline rows="4" rowsMax="8"
            margin="normal"
            value={cardDescription}
            onChange={(e) => this.props.handleMessageInputChange(e, index)}
          />
        </Grid>
        {
          /** 卡片按鈕 */
          cardButtons.map((button, btnIndex) => (
            <React.Fragment key={`card-${index}-button-${btnIndex}`}>
              <Grid item xs={11}>
                <Grid container spacing={16}>
                  <Grid item xs={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="action">按鈕類型</InputLabel>
                      <Select
                        value={button.action}
                        onChange={(e) => this.props.handleCardButtonInputChange(e, index, btnIndex)}
                        inputProps={{
                          name: 'action',
                          id: 'action',
                        }}
                      >
                        {
                          button.actionTypes.map((type, index) => (
                            <MenuItem key={type.label} value={type.value}>{type.label}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField required fullWidth
                      name="label" id="label"
                      label="按鈕文字"
                      className={classes.formControl}
                      value={button.label}
                      onChange={(e) => this.props.handleCardButtonInputChange(e, index, btnIndex)}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField required fullWidth
                      name="actionContent" id="actionContent"
                      label={button.action === 1 ? "連結網址" : "按鈕點擊文字"}
                      className={classes.formControl}
                      value={button.actionContent}
                      onChange={(e) => this.props.handleCardButtonInputChange(e, index, btnIndex)}
                      margin="normal"
                      helperText={button.action === 1 ? "點擊圖片會導向此連結" : "點擊圖片會顯示在 LINE 對話框的文字"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="Delete" className={classes.margin}
                  onClick={() => this.props.removeCardButton(index, btnIndex)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))
        }
        {
          cardButtons.length < 3 &&
          <Grid item xs={12}>
            <Button variant="outlined" color="secondary" className={classes.button}
              onClick={() => this.props.addCardButton(index)}
            >
              增加按鈕
            <AddIcon className={classes.rightIcon} />
            </Button>
          </Grid>
        }
      </Grid>
    )
  }
}

CardMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardMessage);