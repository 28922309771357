import React from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import Edit from '../../component/Tag/Edit'

const queryString = require('query-string')
const isEmpty = require('lodash.isempty')

class AppEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      remark: "",
      lineTextRef: "",
      isWaiting: true,
      isWaitingUpdate: false,
      isRedirectToList: false,

      targetId: null,
      targetTag: null
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getTag = this.getTag.bind(this)
    this.setTag = this.setTag.bind(this)
  }

  componentWillMount() {
    const params = this.props.location.search
    const parsed = queryString.parse(params)
    if (parsed.id) {
      this.setState({ targetId: parsed.id }, () => {
        this.getTag()
      })
    } else {
      this.setState({ isRedirectToList: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      const targetTag = this.props.tags.filter(tag => tag.id === this.state.targetId)[0]
      if (!isEmpty(targetTag)) {
        this.setState({ targetTag }, () => {
          this.setTag()
        })
      } else {
        this.setState({ isRedirectToList: true })
      }
    }
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  getTag() {
    this.props.firestore.get({ collection: "tag", doc: this.state.targetId })
  }

  setTag() {
    const tag = this.state.targetTag
    this.setState({
      name: tag.name,
      remark: tag.remark,
      lineTextRef: tag.lineTextRef,
      isWaiting: false,
    })
  }

  async handleSubmit() {
    const tag = {
      name: this.state.name,
      remark: this.state.remark ? this.state.remark : "",
      lineTextRef: this.state.lineTextRef ? this.state.lineTextRef : "",
    }

    this.setState({ isWaitingUpdate: true })
    await this.props.firestore.update({
      collection: 'tag',
      doc: this.state.targetId,
    }, tag)
    this.setState({ isRedirectToList: true })
  }

  render() {
    if (this.state.isRedirectToList) {
      return <Redirect push to="/tag" />
    }

    return (
      <Edit
        name={this.state.name}
        remark={this.state.remark}
        lineTextRef={this.state.lineTextRef}
        isWaiting={this.state.isWaiting}
        isWaitingUpdate={this.state.isWaitingUpdate}

        handleInputChange={this.handleInputChange}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {
      tags: state.firestore.ordered.tag
    }
  })
)(AppEditContainer)