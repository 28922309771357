import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import MemberNotify from '../../component/Member/Notify'
import agent from '../../agent/agent'

const queryString = require('query-string')

class MemberNotifyContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      title: "",
      content: "",
      targetId: null,
      targetMember: null,
      isWaiting: true,
      isWaitingNotify: false,
      isRedirectToList: false,
      isNotifySuccess: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getMember = this.getMember.bind(this)
    this.setMember = this.setMember.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillMount(){
    const params = this.props.location.search
    const parsed = queryString.parse(params)
    if(parsed.id){
      this.setState({ targetId: parsed.id }, () => {
        this.getMember()
      })
    }else{
      this.setState({ isRedirectToList: true })
    }
  }

  componentDidMount(){
    this.setMember()
  }

  componentDidUpdate(prevProps){
    if(prevProps.members !== this.props.members){
      this.setMember()
    }
  }

  handleInputChange(event){
    this.setState({ [event.target.name]: event.target.value })
  }

  getMember(){
    if(!this.props.members){
      this.props.firestore.get({ collection: "member", doc: this.state.targetId })
    }
  }

  setMember(){
    if(this.props.members){
      const targetMember = this.props.members.filter(member => member.id === this.state.targetId)[0]
      this.setState({ targetMember, isWaiting: false })
    }
  }

  async handleSubmit(){
    const confirm = window.confirm("確定發佈通知嗎？")
    if(confirm){
      this.setState({ isWaitingNotify: true })
      const body = {
        lineId: this.state.targetId,
        title: this.state.title,
        content: this.state.content
      }
      try {
        const result = await agent.Notify.notifyMember(body)
        if(result.status === 204){
          this.setState({ isNotifySuccess: true})
        }else{
          alert("推送訊息時發生錯誤，請告訴ㄎㄑ")
        }
      }catch(err){
        console.error(err)
        alert("推送訊息時發生錯誤，請告訴ㄎㄑ")
      }
    }
  }

  render(){
    if(this.state.isRedirectToList){
      return <Redirect push to="/member" />
    }

    return (
      <MemberNotify
        isWaiting={this.state.isWaiting}
        isWaitingNotify={this.state.isWaitingNotify}
        isNotifySuccess={this.state.isNotifySuccess}
        title={this.state.title}
        content={this.state.content}
        targetMember={this.state.targetMember}
        handleSubmit={this.handleSubmit}
        handleInputChange={this.handleInputChange}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {
      members: state.firestore.ordered.member
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(MemberNotifyContainer))