export const environment = {
  lineLoginServiceUrl: "line://ti/p/%40dyz4848r",
  modelServiceUrl: "https://us-central1-mc-integration-platform.cloudfunctions.net"
  // modelServiceUrl: "http://localhost:5000/mc-integration-platform/us-central1"
};

export const lineLoginConfig = {
  client_id: "1591372143"
}

export const GROUP = {
  1: "個人組",
  2: "自彈自唱組",
  3: "團體組",
  4: "鋼弦演奏組",
  5: "鋼弦創作組",
  6: "創作組",
  7: "高中組",
  8: "高中演唱組",
  9: "高中演奏組",
  10: "獨唱-國台語組",
  11: "獨唱-外語組",
  12: "獨唱組",
  13: "重唱組",
  14: "學生組",
  15: "社會組",
  16: "樂團組",
  17: "演奏組",
  18: "饒舌組",
}