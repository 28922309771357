import React from 'react'

import Paper from '@material-ui/core/Paper';
import {
  ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Legend, Line,
  PieChart, Pie, Cell,
} from 'recharts';

const ExposureData = [
  {
    name: '8/26', uv: 3800, pv: 2400, amt: 2400,
  },
  {
    name: '8/27', uv: 4500, pv: 1398, amt: 2210,
  },
  {
    name: '8/28', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: '8/29', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: '8/30', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: '8/31', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: '9/1', uv: 3490, pv: 4300, amt: 2100,
  },
];

const TransformData = [
  {
    name: '8/26', uv: 4000, pv: 0.3000, amt: 2400,
  },
  {
    name: '8/27', uv: 3000, pv: 0.4200, amt: 2210,
  },
  {
    name: '8/28', uv: 2000, pv: 0.2300, amt: 2290,
  },
  {
    name: '8/29', uv: 2780, pv: 0.1980, amt: 2000,
  },
  {
    name: '8/30', uv: 1890, pv: 0.2290, amt: 2181,
  },
  {
    name: '8/31', uv: 2390, pv: 0.4290, amt: 2500,
  },
  {
    name: '9/1', uv: 3490, pv: 0.3690, amt: 2100,
  },
];

const PieData = [
  { name: 'Group A', value: 700 },
  { name: 'Group B', value: 120 },
  { name: 'Group C', value: 280 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class Report extends React.Component {
  render() {
    return (
      <div className="container-fluid app-article-new">
        <h4>報表 - 本月合作 : KKBOX 學生特惠活動</h4>
        <Paper className="paper">
          <div className="row">
            <div className="col-12">
              <div className="my-3">
                <h5 className="mb-3">曝光量</h5>
                <ResponsiveContainer width="100%" height={300}>
                  <AreaChart
                    data={ExposureData}
                    margin={{
                      top: 10, right: 30, left: 0, bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-5">
              <div className="my-3">
                <h5 className="mb-3">轉換率</h5>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={TransformData}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Line yAxisId="left" type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-3">
              <div className="my-3">
                <h5 className="mb-3">來源比例</h5>
                <PieChart width={400} height={400}>
                  <Pie
                    data={PieData}
                    cx={100}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      PieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                  </Pie>
                </PieChart>
              </div>
            </div>
            <div className="col-4">
              <div className="my-3">
                <h5>使用與觸及標籤</h5>
                <Paper className="px-3 py-2 my-3">
                  <h6>年齡層</h6>
                  <div>
                    <span className="mr-2" style={{ fontSize: "2rem", fontWeight: 500, color: "#3f51b5" }}>1,348</span>
                    個大學生
                  </div>
                  <div>
                    <span className="mr-2" style={{ fontSize: "2rem", fontWeight: 500, color: "#3f51b5" }}>322</span>
                    個社會人士
                  </div>
                </Paper>
                <Paper className="px-3 py-2 my-3">
                  <h6>用戶分類</h6>
                  <div>
                    <span className="mr-2" style={{ fontSize: "2rem", fontWeight: 500, color: "#3f51b5" }}>1,540</span>
                    個會員
                  </div>
                  <div>
                    <span className="mr-2" style={{ fontSize: "2rem", fontWeight: 500, color: "#3f51b5" }}>130</span>
                    個一般用戶
                  </div>    
                </Paper>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

export default Report