import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Close } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ReactMde from "react-mde";
import * as Showdown from "showdown";
import lrz from 'lrz'

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    color: "white"
  },
});

class AppEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: "write"
    }
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleTabChange = this.handleTabChange.bind(this)
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
    this.coverPhotoRef = React.createRef()
    this.handleFileChange = this.handleFileChange.bind(this)
    this.hanldeClickUploadBtn = this.hanldeClickUploadBtn.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  handleTabChange() {
    const tab = this.state.selectedTab === "write" ? "preview" : "write"
    this.setState({ selectedTab: tab })
  }

  hanldeClickUploadBtn() {
    document.getElementById("coverPhoto").click()
  }

  handleFileChange() {
    const file = this.coverPhotoRef.current.files[0]
    lrz(file).then(result => {
      const reader = new FileReader()
      reader.readAsDataURL(result.file)
      reader.onload = () => {
        const base64 = reader.result
        const file = result.file
        const fileName = result.origin.name
        this.props.handleFileChange(base64, file, fileName)
      }
      reader.onerror = (err) => {
        console.error(err)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="container-fluid app-article-edit">
        <h5>編輯文章</h5>
        <Paper className="paper">
          {
            this.props.isWaiting &&
            <CircularProgress className="init-loader" />
          }
          {
            !this.props.isWaiting &&
            <form onSubmit={this.handleSubmit} className="row">
              <div className="col-lg-6 col-12">
                <TextField
                  id="title" name="title" required
                  label="標題"
                  className={classes.textField}
                  value={this.props.title}
                  onChange={this.props.handleInputChange}
                  margin="normal"
                  fullWidth={true}
                />
              </div>
              <div className="col-lg-6 col-12">
                <TextField
                  id="author" name="author" required
                  label="作者"
                  className={classes.textField}
                  value={this.props.author}
                  onChange={this.props.handleInputChange}
                  margin="normal"
                  fullWidth={true}
                />
              </div>
              <div className="col-lg-6 col-12">
                <TextField
                  id="timestamp" name="timestamp"
                  label="時間戳"
                  className={classes.textField}
                  value={this.props.timestamp}
                  onChange={this.props.handleInputChange}
                  margin="normal"
                  fullWidth={true}
                  type="date"
                />
              </div>
              <div className="col-lg-6 col-12">
                <FormControl className="toggle-wrapper" component="fieldset">
                  <FormLabel className="label" component="legend">App 端顯示狀態</FormLabel>
                  <FormControlLabel
                    name="isShow"
                    control={
                      <Switch
                        checked={this.props.isShow}
                        onChange={this.props.handleInputChange}
                        value="gilad"
                      />
                    }
                    label={this.props.isShow ? "顯示" : "隱藏"}
                  />
                </FormControl>
              </div>
              <div className="col-lg-9 col-12">
                <div className="mde-wrapper">
                  <ReactMde
                    onChange={this.props.handleMarkdownChange}
                    onTabChange={this.handleTabChange}
                    value={this.props.article}
                    selectedTab={this.state.selectedTab}
                    generateMarkdownPreview={markdown =>
                      Promise.resolve(this.converter.makeHtml(markdown))
                    }
                  />
                </div>
              </div>
              <div className="col-lg-3 col-12">
                {
                  !this.props.coverPhoto &&
                  <div className="btn-upload-wrapper">
                    <input id="coverPhoto" type="file" accept="image/*" className="d-none" required
                      onChange={this.handleFileChange} ref={this.coverPhotoRef}
                    />
                    <Button variant="contained" color="primary" className={classes.button} fullWidth
                      onClick={this.hanldeClickUploadBtn}
                    >
                      封面照片&nbsp;
                      <CloudUploadIcon className={classes.rightIcon} />
                    </Button>
                  </div>
                }
                {
                  this.props.coverPhoto &&
                  <div className="mde-wrapper cover-photo">
                    <div className="tool-bar" onClick={() => this.props.handleFileChange(null)}>
                      <Close className="icon" />
                      <span className="text">移除</span>
                    </div>
                    <img alt="封面圖" src={this.props.coverPhoto} className="img-fluid" />
                  </div>
                }
              </div>
              <div className="col-12">
                <Button type="submit" variant="contained" color="primary" className={classes.button}
                  disabled={this.props.isWaitingUpdate}
                >
                  {!this.props.isWaitingUpdate && "更新"}
                  {this.props.isWaitingUpdate && <CircularProgress className={classes.progress} size={20} />}
                </Button>
                <Button className={classes.button} disabled={this.props.isWaitingUpdate} component={Link} to="/app-article/">
                  返回
                </Button>
              </div>
            </form>
          }
        </Paper>
      </div>
    )
  }
}

AppEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppEdit);