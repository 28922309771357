import React from 'react'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
});

class ImagemapMessage extends React.Component {
  render() {
    const { classes, imagemapUri, index, imagemapActionTypes, imagemapAction, imagemapActionContent, imagemapAltText } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={6}>
          <TextField required fullWidth
            name="imagemapUri" id="imagemapUri"
            label="圖片網址"
            className={classes.formControl}
            value={imagemapUri}
            onChange={(e) => this.props.handleMessageInputChange(e, index)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="imagemapAction">動作類型</InputLabel>
            <Select
              value={imagemapAction}
              onChange={(e) => this.props.handleMessageInputChange(e, index)}
              inputProps={{
                name: 'imagemapAction',
                id: 'imagemapAction',
              }}
            >
              {
                imagemapActionTypes.map(type => (
                  <MenuItem value={type.value}>{type.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField required fullWidth
            name="imagemapAltText" id="imagemapAltText"
            label="訊息描述"
            className={classes.formControl}
            value={imagemapAltText}
            onChange={(e) => this.props.handleMessageInputChange(e, index)}
            margin="normal"
            helperText="LINE 對話紀錄預顯示文字"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField required fullWidth
            name="imagemapActionContent" id="imagemapActionContent"
            label={imagemapAction === 1 ? "連結網址" : "顯示文字"}
            className={classes.formControl}
            value={imagemapActionContent}
            onChange={(e) => this.props.handleMessageInputChange(e, index)}
            margin="normal"
            helperText={imagemapAction === 1 ? "點擊圖片會導向此連結" : "點擊圖片會顯示在 LINE 對話框的文字"}
          />
        </Grid>
      </Grid>
    )
  }
}

ImagemapMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImagemapMessage);