import React from 'react';
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import {
  CashRefund, FileDocumentEditOutline, Account, Bullhorn, TransitConnection,
  AccountMultiplePlus, Message, Ticket, Settings, Tag, ChartAreaspline,
  Bell, Plus
} from 'mdi-material-ui'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const drawerWidth = 260;

const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSponsorOpen: false,
      isMarketingOpen: false,
      isLineCouponOpen: false,
      isFormOpen: false,
      isTagOpen: false,
    }
    this.toggleCollpase = this.toggleCollpase.bind(this)
  }

  toggleCollpase(key) {
    this.setState({ [key]: !this.state[key] })
  }

  render() {
    const { classes, isDemoMode } = this.props;

    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={this.props.isDrawerOpen}
      >
        <div className={classes.toolbar} />
        <List>
          <ListItem button component={Link} to="/refund">
            <ListItemIcon><CashRefund /></ListItemIcon>
            <ListItemText primary="退費管理" />
          </ListItem>
          <ListItem button component={Link} to="/app-article">
            <ListItemIcon><FileDocumentEditOutline /></ListItemIcon>
            <ListItemText primary="文章管理" />
          </ListItem>
          <ListItem button component={Link} to="/member">
            <ListItemIcon><Account /></ListItemIcon>
            <ListItemText primary="用戶管理" />
          </ListItem>
          <ListItem button onClick={() => this.toggleCollpase('isSponsorOpen')}>
            <ListItemIcon><Bullhorn /></ListItemIcon>
            <ListItemText primary="App 廣告管理" />
            {this.state.isSponsorOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.isSponsorOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to="/sponsor-product">
                <ListItemIcon>
                  <FileDocumentEditOutline />
                </ListItemIcon>
                <ListItemText inset primary="廣告列表" />
              </ListItem>
              <ListItem button className={classes.nested} component={Link} to="/sponsor-product/connect">
                <ListItemIcon>
                  <TransitConnection />
                </ListItemIcon>
                <ListItemText inset primary="廣告連結" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => this.toggleCollpase('isLineCouponOpen')}>
            <ListItemIcon><Ticket /></ListItemIcon>
            <ListItemText primary="LINE Coupon" />
            {this.state.isLineCouponOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.isLineCouponOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to="/line-coupon">
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText inset primary="UTM 設定" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => this.toggleCollpase('isTagOpen')}>
            <ListItemIcon><Tag /></ListItemIcon>
            <ListItemText primary="標籤管理" />
            {this.state.isLineCouponOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.isTagOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to="/tag">
                <ListItemIcon>
                  <FileDocumentEditOutline />
                </ListItemIcon>
                <ListItemText primary="標籤列表" />
              </ListItem>
              <ListItem button className={classes.nested} component={Link} to="/tag/report">
                <ListItemIcon>
                  <ChartAreaspline />
                </ListItemIcon>
                <ListItemText primary="標籤統計" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button component={Link} to="/notification/tag">
            <ListItemIcon><Bell /></ListItemIcon>
            <ListItemText primary="用戶推播" />
          </ListItem>
          <ListItem button onClick={() => this.toggleCollpase('isFormOpen')}>
            <ListItemIcon><FileDocumentEditOutline /></ListItemIcon>
            <ListItemText primary="表單管理" />
            {this.state.isFormOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.isFormOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested} component={Link} to="/form/insert">
                <ListItemIcon>
                  <Plus />
                </ListItemIcon>
                <ListItemText primary="新增表單" />
              </ListItem>
            </List>
          </Collapse>
          {
            isDemoMode &&
            <React.Fragment>
              <ListItem button onClick={() => this.toggleCollpase('isMarketingOpen')}>
                <ListItemIcon><AccountMultiplePlus /></ListItemIcon>
                <ListItemText primary="行銷管理" />
                {this.state.isMarketingOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.isMarketingOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested} component={Link} to="/segment-push">
                    <ListItemIcon>
                      <Message />
                    </ListItemIcon>
                    <ListItemText inset primary="分眾推播" />
                  </ListItem>
                  <ListItem button className={classes.nested} component={Link} to="/data-report">
                    <ListItemIcon>
                      <ChartAreaspline />
                    </ListItemIcon>
                    <ListItemText inset primary="流量統計" />
                  </ListItem>
                  <ListItem button className={classes.nested} component={Link} to="/MA">
                    <ListItemIcon>
                      <ChartAreaspline />
                    </ListItemIcon>
                    <ListItemText inset primary="MA" />
                  </ListItem>
                </List>
              </Collapse>
            </React.Fragment>
          }
        </List>

      </Drawer>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);