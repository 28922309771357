import React from 'react'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const isEmpty = require("lodash.isempty")

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        marginTop: 1.5 * theme.spacing.unit,
        // minWidth: 120,
        // maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
        marginTop: 2 * theme.spacing.unit,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ages = [
    '高中生',
    '大學生',
    '社會人士',
];

const levels = [
    '一般用戶',
    '註冊會員',
];

const types = [
    '參賽者',
    '得獎者',
    '抽獎者',
    '加油團(投票者)',
]

const regions= [
    '北區', 
    '中區', 
    '南區'
]

class Push extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            age: ['高中生', '大學生'],
            level: ['一般用戶', '註冊會員'],
            type: ['參賽者', '抽獎者'],
            region: ['北區', '中區', '南區'],
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangeMultiple = event => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({
            [event.target.name]: value,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div className="container-fluid app-article-new">
                <h5>分眾推播</h5>
                <Paper className="paper">
                    <form onSubmit={this.handleSubmit} className="row">
                        <div className="col-12">
                            <FormControl className={classes.formControl} fullWidth >
                                <InputLabel htmlFor="age">年齡層</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.age}
                                    name="age"
                                    onChange={this.handleChange}
                                    input={<Input id="age" />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {
                                                !isEmpty(selected) &&
                                                selected.map(value => (
                                                    <Chip key={value} label={value} className={classes.chip} color="secondary" />
                                                ))
                                            }
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        !isEmpty(ages) &&
                                        ages.map(name => (
                                            <MenuItem key={name} value={name}
                                            // style={getStyles(name, this)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12">
                            <FormControl className={classes.formControl} fullWidth >
                                <InputLabel htmlFor="level">用戶種類</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.level}
                                    name="level"
                                    onChange={this.handleChange}
                                    input={<Input id="level" />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {
                                                !isEmpty(selected) &&
                                                selected.map(value => (
                                                    <Chip key={value} label={value} className={classes.chip} color="secondary" />
                                                ))
                                            }
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        !isEmpty(levels) &&
                                        levels.map(name => (
                                            <MenuItem key={name} value={name}
                                            // style={getStyles(name, this)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12">
                            <FormControl className={classes.formControl} fullWidth >
                                <InputLabel htmlFor="type">用戶分類</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.type}
                                    name="type"
                                    onChange={this.handleChange}
                                    input={<Input id="type" />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {
                                                !isEmpty(selected) &&
                                                selected.map(value => (
                                                    <Chip key={value} label={value} className={classes.chip} color="secondary" />
                                                ))
                                            }
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        !isEmpty(types) &&
                                        types.map(name => (
                                            <MenuItem key={name} value={name}
                                            // style={getStyles(name, this)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12">
                            <FormControl className={classes.formControl} fullWidth >
                                <InputLabel htmlFor="type">地區</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.region}
                                    name="region"
                                    onChange={this.handleChange}
                                    input={<Input id="region" />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {
                                                !isEmpty(selected) &&
                                                selected.map(value => (
                                                    <Chip key={value} label={value} className={classes.chip} color="secondary" />
                                                ))
                                            }
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {
                                        !isEmpty(regions) &&
                                        regions.map(name => (
                                            <MenuItem key={name} value={name}
                                            // style={getStyles(name, this)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-8">
                            <TextField
                                id="standard-multiline-static"
                                label="標題"
                                // defaultValue="Default Value"
                                className={classes.formControl}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                id="standard-multiline-static"
                                label="主題"
                                // defaultValue="Default Value"
                                className={classes.formControl}
                                margin="normal"
                                fullWidth
                            />
                        </div>
                        <div className="col-8">
                            <TextField
                                id="standard-multiline-static"
                                label="推播內容"
                                multiline
                                rows="6"

                                defaultValue="即日起至 10 月底

                                只要前往全台各大 Studio A 門市打卡，
                                將截圖上傳至樂台計畫！
                                
                                將有機會抽到〈Powerbeats Pro〉完全無線耳機"
                                // defaultValue="Default Value"
                                className={classes.formControl}
                                margin="normal"
                                fullWidth
                                // onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-12">
                            <div className={classes.formControl + " d-flex align-items-center"}>
                                本次將會推播至
                                &nbsp;<strong style={{ color: '#303f9f', fontSize: '1.3rem' }}>2320位</strong>&nbsp;
                                用戶
                            </div>
                        </div>
                        <div className="col-12">
                            <Button variant="contained" color="primary" className={classes.button}>
                                推播
                            </Button>
                        </div>
                    </form>
                </Paper>
            </div>
        )
    }
}

Push.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Push);