import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import MemberList from '../../component/Member/List'

import IconButton from '@material-ui/core/IconButton';
import { Message } from '@material-ui/icons';

class MemberListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: true,
      tableData: [],
      tableColumns: ["LINE ID", "姓名", "電話", "Email", "學制", "學校", ''],
      tableOptions: {
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: "none"
      },
      members: null,
      targetId: null,
    }
    this.getMember = this.getMember.bind(this)
    this.setMember = this.setMember.bind(this)
    this.setTargetId = this.setTargetId.bind(this)
  }

  componentWillMount() {
    this.getMember()
  }

  componentDidMount() {
    this.setMember()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.members !== this.props.members) {
      this.setMember()
    }
  }

  getMember() {
    if (!this.props.members || this.props.members.length === 1) {
      this.props.firestore.get({ collection: 'member', where: ['phone', '>', ''] })
    }
  }

  setMember() {
    if (this.props.members) {
      const tableData = this.props.members.map(member => {
        const { id, name, phone, email, school } = member
        const schoolLevel = school.level
        const schoolName = school.name
        const tools = <IconButton onClick={() => this.setTargetId(member.id)}><Message /></IconButton>
        let schoolLevelName = ''
        switch (schoolLevel) {
          case 1:
          case '1': schoolLevelName = "高中制"; break
          case 2:
          case '2': schoolLevelName = "大學制"; break
          case 3:
          case '3': schoolLevelName = "社會人士"; break
          default: break
        }
        return [id, name, phone, email, schoolLevelName, schoolName, tools]
      })
      this.setState({
        isWaiting: false,
        members: this.props.members,
        tableData
      })
    }
  }

  setTargetId(targetId) {
    this.setState({ targetId })
  }

  render() {
    if (this.state.targetId) {
      return <Redirect push to={`/member/notify?id=${this.state.targetId}`} />
    }

    return (
      <MemberList
        isWaiting={this.state.isWaiting}
        tableData={this.state.tableData}
        tableColumns={this.state.tableColumns}
        tableOptions={this.state.tableOptions}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {
      members: state.firestore.ordered.member,
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(MemberListContainer))