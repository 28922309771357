import agent from './agent'

export const LineCoupon = {
    getCoupons: async () => {
        return new Promise((resolve, reject) => {
            return agent.Request.get(`/lineCoupon`)
                .then(response => resolve(response))
        })
    },
    getCoupon: async (id) => {
        return new Promise((resolve, reject) => {
            return agent.Request.get(`/lineCoupon?id=${id}`)
                .then(response => resolve(response))
        })
    },
    create: async (data) => {
        return new Promise((resolve, reject) => {
            return agent.Request.post(`/lineCoupon`, data)
                .then(response => resolve(response))
        })
    },
    update: async (data) => {
        return new Promise((resolve, reject) => {
            return agent.Request.put(`/lineCoupon`, data)
                .then(response => resolve(response))
        })
    },
}