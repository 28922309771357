import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import New from '../../component/Tag/New'

class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      remark: "",
      lineTextRef: "",
      isRedirectToList: false,
      isWaiting: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  async handleSubmit() {
    this.setState({ isWaiting: true })
    const tag = {
      name: this.state.name,
      remark: this.state.remark,
      lineTextRef: this.state.lineTextRef,
      timestamp: +new Date(),
    }
    try {
      await this.props.firestore.add('tag', tag)
      this.setState({ isRedirectToList: true })
    } catch (err) {
      alert("新增失敗")
    }
    this.setState({ isWaiting: false })
  }

  render() {
    if (this.state.isRedirectToList) {
      return <Redirect push to="/tag" />
    }

    return (
      <New
        name={this.state.name}
        remark={this.state.remark}
        lineTextRef={this.state.lineTextRef}
        isWaiting={this.state.isWaiting}
        handleInputChange={this.handleInputChange}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {

    }
  })
)(NewContainer)