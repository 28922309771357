import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import List from '../../component/SponsorProductConnect/List'

class ListContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      schoolInfo: null,
      targetSchoolId: "",
      isRedirectToTargetSchool: false,
      isWaiting: true
    }
    this.setSchoolInfo = this.setSchoolInfo.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount(){
    this.setSchoolInfo()
  }

  componentDidUpdate(prevProps){
    if(prevProps.schoolInfo !== this.props.schoolInfo){
      this.setSchoolInfo()
    }
  }

  setSchoolInfo(){
    if(this.props.schoolInfo){
      this.setState({ schoolInfo: this.props.schoolInfo, isWaiting: false })
    }
  }

  handleSubmit(){
    if(this.state.targetSchoolId === ""){
      alert("請選擇一間院校哦")
    }else{
      this.setState({ isRedirectToTargetSchool: true })
    }
  }

  handleInputChange(event){
    this.setState({ [event.target.name]: event.target.value })
  }

  render(){
    if(this.state.isRedirectToTargetSchool){
      const pathname = this.props.location.pathname
      return <Redirect push to={`${pathname}/${this.state.targetSchoolId}`} />
    }

    return (
      <List
        schoolInfo={this.state.schoolInfo}
        targetSchoolId={this.state.targetSchoolId}
        isWaiting={this.state.isWaiting}

        handleSubmit={this.handleSubmit}
        handleInputChange={this.handleInputChange}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([
    'school',
  ]),
  connect((state) => {
    return {
      schoolInfo: state.firestore.ordered.school
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(ListContainer))