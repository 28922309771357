import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from "mui-datatables";

import Snackbar from '../../utils/component/Snackbar'

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class Refund extends React.Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }

  render(){
    const { classes } = this.props;

    return (
      <div className="container-fluid">
        { this.props.isWaiting && <CircularProgress className={classes.progress} /> }
        {
          !this.props.isWaiting &&
          <MUIDataTable
            title={"退費管理"}
            data={this.props.tableData}
            columns={this.props.tableColumns}
            options={this.props.tableOptions}
          />
        }
        <Snackbar open={this.props.isSnackbarOpen} variant="success" message="退款通知成功" />
      </div>
    )
  }
}

Refund.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Refund);