import agent from './agent'

export const Refund = {
  getList: async(schoolId) => {
    return new Promise((resolve, reject) => {
      return agent.Request.get(`/refund/member?schoolId=${schoolId}`)
        .then(response => resolve(response))
    })
  },
  setRefunded: async(body) => {
    return new Promise((resolve, reject) => {
      return agent.Request.put(`/refund/member`, body)
        .then(response => resolve(response))
    })
  }
}