import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import classNames from 'classnames';

import lineAuth from './utils/function/lineAuth'
import { setAuthStatus } from './action/auth'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { Cancel } from '@material-ui/icons';
import { Eye } from 'mdi-material-ui'

import Sidebar from './component/Sidebar'

import RefundList from './container/Refund/ListContainer'
import Refund from './container/Refund/RefundContainer'

import AppArticleList from './container/Article/AppListContainer'
import AppArticleNew from './container/Article/AppNewContainer'
import AppArticleEdit from './container/Article/AppEditContainer'

import MemberList from './container/Member/ListContainer'
import MemberNotify from './container/Member/NotifyContainer'

import SponsorProductList from './container/SponsorProduct/ListContainer'
import SponsorProductNew from './container/SponsorProduct/NewContainer'
import SponsorProductEdit from './container/SponsorProduct/EditContainer'

import SponsorProductConnectList from './container/SponsorProductConnect/ListContainer'
import SponsorProductConnect from './container/SponsorProductConnect/ConnectContainer'

import SegmentPush from './component/Marketing/Push'
import DataReport from './component/Marketing/Report'
import MA from './component/Marketing/MA'

import LineCouponList from './container/LineCoupon/ListContainer'
import LineCouponNew from './container/LineCoupon/NewContainer'
import LineCouponEdit from './container/LineCoupon/EditContainer'

import TagList from './container/Tag/ListContainer'
import TagNew from './container/Tag/NewContainer'
import TagEdit from './container/Tag/EditContainer'
import TagReport from './container/Tag/ReportContainer'

import NotifcationByTag from './container/Notification/ByTagContainer'
import NotifcationByTagSuccess from './container/Notification/SuccessPage'

import FormInsert from './container/Form/InsertContainer'
import FormInsertSuccess from './container/Form/SuccessPage'

const drawerWidth = 260;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const AuthFail = props => {
  return (
    <Paper className="auth-fail">
      <Cancel color="secondary" className="icon" />
      <span className="text">權限不足</span>
    </Paper>
  )
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lineAuth: new lineAuth(this.props.cookies, this.props.location, this.props.setAuthStatus),

      isDemoMode: false,
      anchorEl: null,

      isDrawerOpen: true,
      isSponsorOpen: false,
      isMarketingOpen: false,
    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.closeDrawer = this.closeDrawer.bind(this)

    this.handleMenu = this.handleMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
    this.toggleDemoMode = this.toggleDemoMode.bind(this)
  }

  componentDidMount() {
    this.props.history.listen(() => { window.scrollTo(0, 0) })
    this.closeDrawer()
    window.onresize = () => { this.closeDrawer() }
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
  }

  closeDrawer() {
    const windowWidth = window.innerWidth
    if (windowWidth <= 768) {
      this.setState({ isDrawerOpen: false })
    }
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleCloseMenu() {
    this.setState({ anchorEl: null });
  }

  toggleDemoMode() {
    this.setState({ isDemoMode: !this.state.isDemoMode })
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, isDemoMode, isDrawerOpen } = this.state

    if (this.props.isAuth === null) {
      return <CircularProgress />
    }

    if (this.props.isAuth !== true) {
      return <AuthFail />
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" style={{ whiteSpace: 'nowrap' }}>
              樂台計畫
            </Typography>
            <div style={{ width: '100%', textAlign: 'right' }}>
              <IconButton
                aria-owns={Boolean(anchorEl) ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={this.toggleDemoMode}>
                  <ListItemIcon>
                    <Eye />
                  </ListItemIcon>
                  Demo 模式：{isDemoMode ? '開' : '關'}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Sidebar isDrawerOpen={isDrawerOpen} isDemoMode={isDemoMode} />
        <main className={classNames(classes.content, { [classes.contentShift]: isDrawerOpen })}  >
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/refund" name="refund" component={RefundList} />
            <Route exact path="/refund/:schoolId" name="Refund" component={Refund} />
            <Route exact path="/app-article" name="AppArticleList" component={AppArticleList} />
            <Route exact path="/app-article/new" name="AppArticleNew" component={AppArticleNew} />
            <Route exact path="/app-article/edit" name="AppArticleNew" component={AppArticleEdit} />
            <Route exact path="/member" name="Member" component={MemberList} />
            <Route exact path="/member/notify" name="MemberNotify" component={MemberNotify} />
            <Route exact path="/sponsor-product" name="SponsorProduct" component={SponsorProductList} />
            <Route exact path="/sponsor-product/new" name="SponsorProductNew" component={SponsorProductNew} />
            <Route exact path="/sponsor-product/edit" name="SponsorProductEdit" component={SponsorProductEdit} />
            <Route exact path="/sponsor-product/connect" name="SponsorProductConnectList" component={SponsorProductConnectList} />
            <Route exact path="/sponsor-product/connect/:schoolId" name="SponsorProductConnect" component={SponsorProductConnect} />
            <Route exact path="/line-coupon" name="LineCouponList" component={LineCouponList} />
            <Route exact path="/line-coupon/new" name="LineCouponNew" component={LineCouponNew} />
            <Route exact path="/line-coupon/edit" name="LineCouponEdit" component={LineCouponEdit} />
            <Route exact path="/tag" name="TagList" component={TagList} />
            <Route exact path="/tag/new" name="TagNew" component={TagNew} />
            <Route exact path="/tag/edit" name="TagEdit" component={TagEdit} />
            <Route exact path="/tag/report" name="TagReport" component={TagReport} />
            <Route exact path="/notification/tag" name="NotificationByTag" component={NotifcationByTag} />
            <Route exact path="/notification/tag/success" name="NotificationByTagSuccess" component={NotifcationByTagSuccess} />
            <Route exact path="/form/insert" name="FormInsert" component={FormInsert} />
            <Route exact path="/form/insert/success" name="FormInsertSuccess" component={FormInsertSuccess} />

            {/* Demo Page */}
            <Route exact path="/segment-push" name="SegmentPush" component={SegmentPush} />
            <Route exact path="/data-report" name="DataReport" component={DataReport} />
            <Route exact path="/MA" name="MA" component={MA} />
          </Switch>
        </main>
      </div>
    );

  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
})

const mapDispatchToProps = dispatch => ({
  setAuthStatus: isAuth => {
    dispatch(setAuthStatus(isAuth))
  }
})

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(withCookies(App))))
