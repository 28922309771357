import agent from './agent'

export const Notify = {
  notifyMember: async (body) => {
    return new Promise((resolve, reject) => {
      return agent.Request.post(`/notify/member`, body)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  notifyTag: async (body) => {
    return new Promise((resolve, reject) => {
      return agent.Request.post(`/notify/tag`, body)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}