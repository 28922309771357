import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, } from 'mdi-material-ui'

const isEmpty = require("lodash.isempty")

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
});

class New extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }


  render() {
    const { classes } = this.props;
    return (
      <div className="container-fluid sponsor-product-connect">
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.props.isShowSnackbar}
          onClose={this.props.closeSnackbar}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">連結成功</span>}
        />
        {this.props.isWaiting && <CircularProgress className={classes.progress} />}
        {
          !this.props.isWaiting &&
          <React.Fragment>
            <h5>廣告連結 - {this.props.targetSchool.name} {this.props.targetSchool.period} 屆</h5>
            <Paper className="paper">
              <form onSubmit={this.handleSubmit} className="row">
                <div className="col-12">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="participantProductsSelected">參賽者方案</InputLabel>
                    <Select
                      name="participantProductsSelected"
                      value={this.props.participantProductsSelected}
                      onChange={this.props.handleInputChange}
                      inputProps={{
                        name: 'participantProductsSelected',
                        id: 'participantProductsSelected',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {
                        !isEmpty(this.props.sponsorProducts) &&
                        this.props.sponsorProducts.map(product => {
                          return <MenuItem key={`popular${product.id}`} value={product.id}>{product.title}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="popularProductsSelected">人氣投票方案</InputLabel>
                    <Select
                      name="popularProductsSelected"
                      value={this.props.popularProductsSelected}
                      onChange={this.props.handleInputChange}
                      inputProps={{
                        name: 'popularProductsSelected',
                        id: 'popularProductsSelected',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {
                        !isEmpty(this.props.sponsorProducts) &&
                        this.props.sponsorProducts.map(product => {
                          return <MenuItem key={`popular${product.id}`} value={product.id}>{product.title}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12">
                  <div className="row">
                    {
                      !isEmpty(this.props.lotteryInfo) &&
                      this.props.lotteryInfo.map(lottery => {
                        const targetSelected = this.props.lotteryProductsSelected.find(product => product.id === lottery.id)
                        return (
                          <div className="col-lg-4 col-md-6 col-12" key={lottery.id}>
                            <FormControl className={classes.formControl} fullWidth>
                              <InputLabel shrink htmlFor={lottery.id}>
                                {lottery.title}
                              </InputLabel>
                              <Select
                                name={lottery.id}
                                value={targetSelected.value}
                                onChange={this.props.handleLotteryConnectChange}
                                displayEmpty
                                inputProps={{
                                  name: lottery.id,
                                  id: lottery.id,
                                }}
                              >
                                <MenuItem value="">
                                  <em>選擇連結方案</em>
                                </MenuItem>
                                {
                                  !isEmpty(this.props.sponsorProducts) &&
                                  this.props.sponsorProducts.map(product => {
                                    return <MenuItem key={`popular${product.id}`} value={product.id}>{product.title}</MenuItem>
                                  })
                                }
                              </Select>
                            </FormControl>
                          </div>
                        )
                      })
                    }
                  </div>

                </div>
                {
                  this.props.errorMsg &&
                  <div className="col-12">
                    <div className="error">
                      <Alert className="icon" />
                      <strong>{this.props.errorMsg}</strong>
                    </div>
                  </div>
                }
                <div className="col-12">
                  <Button type="submit" variant="contained" color="primary" className={classes.button}
                    disabled={this.props.isWaitingSubmit}
                  >
                    {!this.props.isWaitingSubmit && "連結"}
                    {this.props.isWaitingSubmit && <CircularProgress className={classes.progress} size={20} />}
                  </Button>
                  <Button className={classes.button} disabled={this.props.isWaitingSubmit} component={Link} to="/sponsor-product/connect">
                    返回
                  </Button>
                </div>
              </form>
            </Paper>
          </React.Fragment>
        }
      </div>
    )
  }
}

New.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(New);