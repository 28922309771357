import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CheckCircle } from '@material-ui/icons';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  icon: {
    fontSize: 32,
    color: "#28a745"
  }
});

class SuccessPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className="notification-success-page">
        <CheckCircle className="icon" />
        <div className="text">新增成功</div>
        <Button className={classes.button} component={Link} to="/form/insert">
          返回
        </Button>
      </Paper>
    )
  }
}

SuccessPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SuccessPage);
