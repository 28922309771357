import {
    RECEIVE_ALL_MEMBERS,
} from '../action/member'

const initialState = {
    members: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_ALL_MEMBERS:
            return {
                ...state,
                members: action.members
            }
        default:
            return state
    }
}