import React from 'react'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';

import ImagemapMessage from './ImagemapMessage'
import CardMessage from './CardMessage'
import MessageMenu from './MessageMenu'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "5rem"
  },
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

class ByTag extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  render() {
    const { classes, tags, tagGroup, messageGroup, anchorEl, anchorIndex, isWaitingSubmit } = this.props;
    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <form onSubmit={this.handleSubmit}>
            <Paper className={classes.control + " mb-3"}>
              {
                !tags &&
                <CircularProgress className={classes.progress} />
              }
              {
                tags &&
                <React.Fragment>
                  <h5>標籤推播</h5>
                  {/* 標籤選擇 */}
                  <Grid container spacing={16}>
                    {
                      tagGroup.map((tag, index) => {
                        return (
                          <React.Fragment key={`tagGroup${index}`}>
                            <Grid item xs={11}>
                              <Grid container spacing={16}>
                                <Grid item xs={8}>
                                  <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel htmlFor="tagSelected">標籤</InputLabel>
                                    <Select
                                      value={tag.tagSelected}
                                      onChange={(e) => this.props.handleTagInputChange(e, index)}
                                      inputProps={{
                                        name: 'tagSelected',
                                        id: 'tagSelected',
                                      }}
                                    >
                                      {
                                        tags.map(tag => (
                                          <MenuItem key={tag.id} value={tag.id}>
                                            {tag.name}
                                          </MenuItem>
                                        ))
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField fullWidth
                                    id="limit"
                                    name="limit"
                                    label="次數限制"
                                    type="number"
                                    className={classes.formControl}
                                    value={tag.limit}
                                    onChange={(e) => this.props.handleTagInputChange(e, index)}
                                    margin="normal"
                                    helperText="用戶 Tag 數量需大於(含)限制才會收到通知"
                                  />
                                </Grid>

                              </Grid>
                            </Grid>
                            <Grid item xs={1} style={{ margin: 'auto' }}>
                              <IconButton aria-label="Delete" className={classes.margin}
                                onClick={() => this.props.removeTag(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </React.Fragment>
                        )
                      })
                    }
                    <Grid item xs={12}>
                      <Button variant="outlined" color="secondary" className={classes.button}
                        onClick={this.props.addTag}
                      >
                        增加 Tag
                        <AddIcon className={classes.rightIcon} />
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </Paper>
            {
              messageGroup.map((message, index) => {
                return (
                  <Paper key={`message${index}`} className={classes.control + " my-3"}>
                    <Grid container>
                      <Grid item xs={12}>
                        <h5 className="d-flex justify-content-between align-items-center">
                          訊息 {index + 1}
                          <IconButton aria-label="Delete" onClick={(e) => this.props.setAnchorEl(e, index)}
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </h5>
                        {/* 訊息型態選擇 */}
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel htmlFor="messageType">訊息型態</InputLabel>
                          <Select
                            value={message.messageType}
                            onChange={(e) => this.props.handleMessageInputChange(e, index)}
                            inputProps={{
                              name: 'messageType',
                              id: 'messageType',
                            }}
                          >
                            {
                              message.messageTypes.map(msg => (
                                <MenuItem key={`msg${msg.value}`} value={msg.value}>{msg.label}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                        {
                          message.messageType === 1 &&
                          <TextField fullWidth label="標題" required
                            name="textTitle" id="textTitle" className={classes.formControl}
                            margin="normal"
                            value={message.textTitle}
                            onChange={(e) => this.props.handleMessageInputChange(e, index)}
                          />
                        }
                        {
                          message.messageType === 1 &&
                          <TextField fullWidth label="文字訊息" required
                            name="textContent" id="textContent" className={classes.formControl}
                            multiline rows="4" rowsMax="8"
                            margin="normal"
                            value={message.textContent}
                            onChange={(e) => this.props.handleMessageInputChange(e, index)}
                          />
                        }
                        {
                          message.messageType === 2 &&
                          <ImagemapMessage
                            index={index}
                            imagemapUri={message.imagemapUri}
                            imagemapAltText={message.imagemapAltText}
                            imagemapActionTypes={message.imagemapActionTypes}
                            imagemapAction={message.imagemapAction}
                            imagemapActionContent={message.imagemapActionContent}
                            handleMessageInputChange={this.props.handleMessageInputChange}
                          />
                        }
                        {
                          message.messageType === 3 &&
                          <CardMessage
                            index={index}
                            cardImageUri={message.cardImageUri}
                            cardTitle={message.cardTitle}
                            cardDescription={message.cardDescription}
                            cardButtons={message.cardButtons}
                            handleMessageInputChange={this.props.handleMessageInputChange}
                            handleCardButtonInputChange={this.props.handleCardButtonInputChange}
                            addCardButton={this.props.addCardButton}
                            removeCardButton={this.props.removeCardButton}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Paper>
                )
              })
            }
            <Grid item xs={12}>
              <Paper className={classes.control + " my-3"}>
                <Button type="submit" variant="contained" color="primary" className={classes.button}
                  disabled={isWaitingSubmit}
                >
                  {isWaitingSubmit ? <CircularProgress size={20} className="text-white" /> : "推播"}
                </Button>
                {
                  messageGroup.length < 3 &&
                  <Button color="primary" className={classes.button} onClick={this.props.addMessage}
                    disabled={isWaitingSubmit}
                  >
                    增加訊息
                  </Button>
                }
              </Paper>
            </Grid>
            <MessageMenu
              anchorEl={anchorEl}
              handleClose={() => this.props.setAnchorEl(null)}
              removeMessage={() => this.props.removeMessage(anchorIndex)}
            />
          </form>
        </Grid>
      </Grid >
    )
  }
}

ByTag.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ByTag);