import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { CheckCircle } from '@material-ui/icons';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  icon: {
    fontSize: 32,
    color: "#28a745"
  }
});

class Notify extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="container-fluid notify">
        <h5>訊息通知</h5>
        <Paper className="paper">
          {
            this.props.isNotifySuccess &&
            <div className="success-msg">
              <CheckCircle className="icon" />
              <div className="text">通知成功</div>
              <Button className={classes.button} component={Link} to="/member">
                返回
              </Button>
            </div>
          }
          {
            this.props.isWaiting &&
            !this.props.isNotifySuccess &&
            <CircularProgress className="init-loader" />
          }
          {
            !this.props.isWaiting &&
            !this.props.isNotifySuccess &&
            <form onSubmit={this.handleSubmit} className="row">
              <div className="col-lg-4 col-12">
                <TextField
                  label="姓名"
                  className={classes.textField}
                  value={this.props.targetMember.name}
                  margin="normal"
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className="col-lg-4 col-12">
                <TextField
                  label="Email"
                  className={classes.textField}
                  value={this.props.targetMember.email}
                  margin="normal"
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className="col-lg-4 col-12">
                <TextField
                  label="電話"
                  className={classes.textField}
                  value={this.props.targetMember.phone}
                  margin="normal"
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-12">
                <TextField
                  label="學制"
                  className={classes.textField}
                  value={this.props.targetMember.school.level}
                  margin="normal"
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-12">
                <TextField
                  label="學校"
                  className={classes.textField}
                  value={this.props.targetMember.school.name}
                  margin="normal"
                  fullWidth={true}
                  disabled
                />
              </div>
              <div className="col-12">
                <TextField
                  name="title"
                  label="標題"
                  className={classes.textField}
                  value={this.props.title}
                  margin="normal"
                  fullWidth={true}
                  onChange={this.props.handleInputChange}
                  required
                />
              </div>
              <div className="col-12">
                <TextField
                  name="content"
                  label="內容"
                  className={classes.textField}
                  value={this.props.content}
                  margin="normal"
                  fullWidth={true}
                  onChange={this.props.handleInputChange}
                  multiline
                  rows="4"
                  required
                />
              </div>
              <div className="col-12">
                <Button type="submit" variant="contained" color="primary" className={classes.button}
                  disabled={this.props.isWaitingNotify}
                >
                  {!this.props.isWaitingNotify && "通知"}
                  {this.props.isWaitingNotify && <CircularProgress className={classes.progress} size={20} />}
                </Button>
                <Button className={classes.button} disabled={this.props.isWaitingNotify} component={Link} to="/member">
                  返回
              </Button>
              </div>

            </form>
          }

        </Paper>
      </div>
    )
  }
}

Notify.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notify);
