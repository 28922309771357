export const TYPE = [
    {
        label: "截圖上傳",
        value: 1,
    },
    {
        label: "影片置入",
        value: 2,
    },
    {
        label: "直接參與",
        value: 3,
    },
]