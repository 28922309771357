import React from 'react'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AddCircle, Cancel } from '@material-ui/icons'

import MemberTable from './MemberTable'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
})

const EquipmentSelection = props => {
  const maxNumArray = []
  for (let i = 0; i <= props.max; i++) maxNumArray.push(i)

  return (
    <Grid item xs={3} key={`member`}>
      <FormControl fullWidth className={props.classes.formControl}>
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        <Select inputProps={{ name: props.name, id: props.name }}
          value={props.value}
          onChange={props.onChange}
        >
          {
            maxNumArray.map(value =>
              <MenuItem key={props.label + value} value={value}>{value}</MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Grid>
  )
}

const Members = props => {
  return (
    <React.Fragment>
      {
        props.members.map((member, index) =>
          <Grid item xs={3} key={`member${index}`}>
            <TextField label="參賽夥伴" name="name"
              fullWidth className={props.classes.formControl}
              value={member}
              onChange={(e) => props.onChange(e, index)}
              placeholder="學校 / 姓名 / 負責樂器"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <React.Fragment>
                      {
                        props.members &&
                        props.members.length === index + 1 &&
                        props.members.length !== props.numOfPartner &&
                        <IconButton onClick={props.onAddMembers} >
                          <AddCircle />
                        </IconButton>
                      }
                      <IconButton onClick={() => props.onSubMeber(index)} >
                        <Cancel />
                      </IconButton>
                    </React.Fragment>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )
      }
    </React.Fragment>
  )
}

class Insert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  render() {
    const { classes, membersTableData, name, email, school, schools, group, groups, targetSchool, song, last5Code,
      mic, micStand, guitar, bass, cajon, kb, chair, effector, remark,
      micMax, micStandMax, guitarMax, bassMax, cajonMax, kbMax, numOfPartner, isEffector,
      partners, commonField, customField,
      isMemberModalOpen, isWaitingSubmit,
    } = this.props;

    if (!schools) {
      return <CircularProgress className={classes.progress} />
    }

    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <h5>新增表單</h5>
          <Paper className="paper">
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="targetSchool">賽事名稱</InputLabel>
                    <Select onChange={this.props.handleTargetSchoolChange}
                      value={targetSchool ? targetSchool.id : ''}
                      inputProps={{
                        name: 'targetSchool', id: 'targetSchool',
                      }}
                    >
                      {
                        schools &&
                        schools.map((school) => {
                          return <MenuItem key={school.id} value={school.id}>{school.name}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label="用戶"
                    id="name" name="name"
                    className={classes.formControl}
                    value={name ? `${name} / ${school} / ${email} ` : ''}
                    readOnly
                    margin="normal"
                    onClick={() => { this.props.setModalState('isMemberModalOpen', true) }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="group">參賽組別</InputLabel>
                    <Select
                      value={group} onChange={this.props.handleGroupChange}
                      inputProps={{
                        name: 'group', id: 'group',
                      }}
                    >
                      <MenuItem value=""><em>None</em></MenuItem>
                      {
                        groups &&
                        groups.map((group) => {
                          return <MenuItem key={group.label} value={group.value}>{group.label}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label="表演曲目" required
                    id="song" name="song"
                    className={classes.formControl}
                    value={song} onChange={this.props.handleInputChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={16}>
                    {
                      micMax !== 0 &&
                      <EquipmentSelection label="器材 - 麥克風" name="mic" max={micMax}
                        value={mic} onChange={this.props.handleInputChange}
                        classes={classes}
                      />
                    }
                    {
                      micStandMax !== 0 &&
                      <EquipmentSelection label="器材 - 麥克風架" name="micStand" max={micStandMax}
                        value={micStand} onChange={this.props.handleInputChange}
                        classes={classes}
                      />
                    }
                    {
                      guitarMax !== 0 &&
                      <EquipmentSelection label="器材 - 吉他" name="guitar" max={guitarMax}
                        value={guitar} onChange={this.props.handleInputChange}
                        classes={classes}
                      />
                    }
                    {
                      bassMax !== 0 &&
                      <EquipmentSelection label="器材 - Bass" name="bass" max={bassMax}
                        value={bass} onChange={this.props.handleInputChange}
                        classes={classes}
                      />
                    }
                    {
                      cajonMax !== 0 &&
                      <EquipmentSelection label="器材 - Cajon" name="cajon" max={cajonMax}
                        value={cajon} onChange={this.props.handleInputChange}
                        classes={classes}
                      />
                    }
                    {
                      kbMax !== 0 &&
                      <EquipmentSelection label="器材 - KB" name="kb" max={kbMax}
                        value={kb} onChange={this.props.handleInputChange}
                        classes={classes}
                      />
                    }
                    <EquipmentSelection label="器材 - 椅子" name="chair" max={6}
                      value={chair} onChange={this.props.handleInputChange}
                      classes={classes}
                    />
                  </Grid>
                </Grid>
                {
                  !!numOfPartner &&
                  <Grid item xs={12}>
                    <Grid container spacing={16}>
                      <Members label="參賽夥伴"
                        members={partners}
                        numOfPartner={numOfPartner}
                        onAddMembers={this.props.addMember}
                        onSubMeber={this.props.removeMember}
                        onChange={this.props.handleMemberChange}
                        classes={classes}
                      />
                    </Grid>
                  </Grid>
                }
                {
                  isEffector &&
                  <Grid item xs={4}>
                    <TextField fullWidth label="效果器"
                      id="effector" name="effector"
                      className={classes.formControl}
                      value={effector} onChange={this.props.handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                }
                {
                  targetSchool &&
                  !targetSchool.isECPay &&
                  <Grid item xs={4}>
                    <TextField fullWidth label="匯款後 5 碼"
                      id="last5Code" name="last5Code"
                      className={classes.formControl}
                      value={last5Code} onChange={this.props.handleInputChange}
                      margin="normal"
                    />
                  </Grid>
                }
                <Grid item xs={4}>
                  <TextField fullWidth label="備註"
                    id="remark" name="remark"
                    className={classes.formControl}
                    value={remark} onChange={this.props.handleInputChange}
                    margin="normal"
                  />
                </Grid>
                {
                  commonField &&
                  <Grid item xs={12}>
                    <Grid container spacing={16}>
                      {
                        commonField.map((field, index) =>
                          <Grid item xs={4} key={`commonField${index}`}>
                            <TextField label={field.label} value={field.value}
                              required={field.isRequired}
                              fullWidth className={classes.formControl}
                              onChange={(e) => this.props.handleCommonFieldChange(e, index)}
                            />
                          </Grid>
                        )
                      }
                    </Grid>
                  </Grid>
                }
                {
                  customField &&
                  <Grid item xs={12}>
                    <Grid container spacing={16}>
                      {
                        customField.map((field, index) =>
                          <Grid item xs={4} key={`customField${index}`}>
                            <TextField label={field.label} value={field.value}
                              required={field.isRequired}
                              fullWidth className={classes.formControl}
                              onChange={(e) => this.props.handleCustomFieldChange(e, index)}
                            />
                          </Grid>
                        )
                      }
                    </Grid>
                  </Grid>
                }
                <Grid item xs={12}>
                  <div className={classes.formControl}>
                    <Button type="submit" variant="contained" color="primary" className={classes.button}
                      disabled={isWaitingSubmit}
                    >
                      {isWaitingSubmit ? <CircularProgress size={20} className="text-white" /> : "送出"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <MemberTable
            isMemberModalOpen={isMemberModalOpen}
            tableData={membersTableData}
            setModalState={this.props.setModalState}
            setTargetMember={this.props.setTargetMember}
          />
        </Grid>
      </Grid>
    )
  }
}

Insert.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Insert);