import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'

import ByTag from '../../component/Notification/ByTag'
import agent from '../../agent/agent'

const cloneDeep = require("lodash.clonedeep")

const MESSAGE_TYPES = [
  { label: "文字", value: 1 },
  { label: "圖片 (Imagemap)", value: 2 },
  { label: "卡片 (Flex Card)", value: 3 },
]

const ACTION_TYPES = [
  { label: "URL 網址", value: 1 },
  { label: "文字訊息", value: 2 },
]

const CARD_BUTTON_FORMAT = {
  label: "",
  actionTypes: ACTION_TYPES,
  action: 1,
  actionContent: "",
}

const MESSAGE_FORMAT = {
  messageTypes: MESSAGE_TYPES,
  messageType: 3,

  /** message type 1 */
  textTitle: "",
  textContent: "",

  /** message type 2 */
  imagemapUri: "https://i.imgur.com/FJ4SkR7.png",
  imagemapAltText: "",
  imagemapActionTypes: ACTION_TYPES,
  imagemapAction: 1,
  imagemapActionContent: "",

  /** message type 3 */
  cardImageUri: "https://i.imgur.com/FJ4SkR7.png",
  cardTitle: "",
  cardDescription: "",
  cardButtons: [CARD_BUTTON_FORMAT]
}

const TAG_FORMAT = {
  tagSelected: "",
  limit: 1,
}

class ByTagContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tagGroup: [TAG_FORMAT],
      messageGroup: [MESSAGE_FORMAT],

      anchorEl: null,
      anchorIndex: null,
      isWaitingSubmit: false,
    }
    this.handleTagInputChange = this.handleTagInputChange.bind(this)
    this.handleMessageInputChange = this.handleMessageInputChange.bind(this)
    this.handleCardButtonInputChange = this.handleCardButtonInputChange.bind(this)

    this.addTag = this.addTag.bind(this)
    this.removeTag = this.removeTag.bind(this)
    this.addMessage = this.addMessage.bind(this)
    this.removeMessage = this.removeMessage.bind(this)

    this.addCardButton = this.addCardButton.bind(this)
    this.removeCardButton = this.removeCardButton.bind(this)

    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleTagInputChange(event, index) {
    const tagGroup = cloneDeep(this.state.tagGroup)
    tagGroup[index][event.target.name] = event.target.value
    this.setState({ tagGroup })
  }

  handleMessageInputChange(event, index) {
    const messageGroup = cloneDeep(this.state.messageGroup)
    messageGroup[index][event.target.name] = event.target.value
    this.setState({ messageGroup })
  }

  handleCardButtonInputChange(event, messageIndex, buttonIndex) {
    const messageGroup = cloneDeep(this.state.messageGroup)
    messageGroup[messageIndex]['cardButtons'][buttonIndex][event.target.name] = event.target.value
    this.setState({ messageGroup })
  }

  addTag() {
    const tagGroup = cloneDeep(this.state.tagGroup)
    tagGroup.push(TAG_FORMAT)
    this.setState({ tagGroup })
  }

  removeTag(index) {
    const tagGroup = cloneDeep(this.state.tagGroup)
    tagGroup.splice(index, 1)
    this.setState({ tagGroup })
  }

  addMessage() {
    const messageGroup = cloneDeep(this.state.messageGroup)
    messageGroup.push(MESSAGE_FORMAT)
    this.setState({ messageGroup })
  }

  removeMessage(index) {
    const messageGroup = cloneDeep(this.state.messageGroup)
    messageGroup.splice(index, 1)
    this.setState({ messageGroup })
    this.setAnchorEl(null)
  }

  addCardButton(index) {
    const messageGroup = cloneDeep(this.state.messageGroup)
    messageGroup[index][`cardButtons`].push(CARD_BUTTON_FORMAT)
    this.setState({ messageGroup })
  }

  removeCardButton(messageIndex, buttonIndex) {
    const messageGroup = cloneDeep(this.state.messageGroup)
    messageGroup[messageIndex].cardButtons.splice(buttonIndex, 1)
    this.setState({ messageGroup })
  }

  setAnchorEl(event, index) {
    this.setState({
      anchorEl: event ? event.currentTarget : null,
      anchorIndex: event ? index : null
    })
  }

  async handleSubmit() {
    this.setState({ isWaitingSubmit: true })
    const tag = this.state.tagGroup.map(item => {
      return ({
        id: item.tagSelected,
        limit: item.limit
      })
    })
    const message = this.state.messageGroup.map(item => {
      console.log(item)
      switch (item.messageType) {
        case 1:
          return ({
            type: 1,
            title: item.textTitle,
            content: item.textContent
          })
        case 2:
          return ({
            type: 2,
            altText: item.imagemapAltText,
            imageUrl: item.imagemapUri,
            actionType: item.imagemapAction,
            content: item.imagemapActionContent
          })
        case 3:
          return ({
            type: 3,
            title: item.cardTitle,
            imageUrl: item.cardImageUri,
            content: item.cardDescription,
            button: item.cardButtons.map((btn, index) => {
              return ({
                type: btn.action,
                label: btn.label,
                content: btn.actionContent
              })
            })
          })
        default:
          return {}
      }
    })

    try {
      await agent.Notify.notifyTag({ tag, message })
        .then(result => {
          this.setState({ isNotifySuccess: result.status === 204 })
          console.log(`result`, result)
        })
    } catch (error) {
      console.log(error)
      alert("發生錯誤，請將訊息截圖至管理員")
    }

    this.setState({ isWaitingSubmit: false })
  }

  render() {
    if (this.state.isNotifySuccess) {
      return <Redirect push to="/notification/tag/success" />
    }
    return (
      <ByTag
        tags={this.props.tags}
        tagGroup={this.state.tagGroup}
        messageGroup={this.state.messageGroup}
        isWaitingSubmit={this.state.isWaitingSubmit}

        handleTagInputChange={this.handleTagInputChange}
        handleMessageInputChange={this.handleMessageInputChange}
        handleCardButtonInputChange={this.handleCardButtonInputChange}
        handleSubmit={this.handleSubmit}

        addTag={this.addTag}
        removeTag={this.removeTag}
        addMessage={this.addMessage}
        removeMessage={this.removeMessage}

        addCardButton={this.addCardButton}
        removeCardButton={this.removeCardButton}

        anchorEl={this.state.anchorEl}
        anchorIndex={this.state.anchorIndex}
        setAnchorEl={this.setAnchorEl}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([
    'tag' // { path: '/route...' }
  ]),
  connect((state) => {
    return {
      tags: state.firestore.ordered.tag
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(ByTagContainer))