import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import List from '../../component/SponsorProduct/List'

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Edit } from '@material-ui/icons';
import { TYPE } from '../../config/sponsorProduct'

class ListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: true,
      tableData: [],
      tableColumns: ["標題", "類型", "時間戳", ''],
      tableOptions: {
        customToolbar: () => {
          return (
            <Tooltip title="New Product">
              <IconButton component={Link} to="/sponsor-product/new">
                <AddIcon />
              </IconButton>
            </Tooltip>
          )
        },
        selectableRows: "none"
      },
      products: null,
      editId: null,
    }
    this.getSponsorProduct = this.getSponsorProduct.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  componentDidMount() {
    this.getSponsorProduct()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sponsorProduct !== this.props.sponsorProduct) {
      this.getSponsorProduct()
    }
  }

  getSponsorProduct() {
    if (this.props.sponsorProduct) {
      const tableData = this.props.sponsorProduct.map(product => {
        const targetType = TYPE.find(type => type.value === product.type)

        const title = product.title
        const type = targetType ? targetType.label : ''
        const timestamp = dayjs(product.timestamp).format("YYYY-MM-DD HH:mm")
        const tools = <IconButton onClick={() => this.handleEdit(product.id)}><Edit /></IconButton>

        return [title, type, timestamp, tools]
      })
      this.setState({ tableData, products: this.props.sponsorProduct, isWaiting: false })
    }
  }

  handleEdit(editId) {
    this.setState({ editId })
  }

  render() {
    if (this.state.editId) {
      return <Redirect push to={`/sponsor-product/edit?id=${this.state.editId}`} />
    }

    return (
      <List
        isWaiting={this.state.isWaiting}
        products={this.state.products}
        tableData={this.state.tableData}
        tableColumns={this.state.tableColumns}
        tableOptions={this.state.tableOptions}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([
    'restrict' // { path: '/route...' }
  ]),
  connect((state) => {
    return {
      sponsorProduct: state.firestore.ordered.restrict
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(ListContainer))