import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import AppList from '../../component/Article/AppList'

import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckCircle, Edit } from '@material-ui/icons';


class AppListContainer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isWaiting: true,
      tableData: [],
      tableColumns: ["標題", "作者", "時間戳", "已展示", ''],
      tableOptions: {
        print: false,
        download: false,
        viewColumns: false,
        customToolbar: () => {
          return (
            <Tooltip title="New Article">
              <IconButton component={Link} to="/app-article/new">
                <AddIcon/>
              </IconButton>
            </Tooltip>
          )
        },
        selectableRows: "none"
      },
      articles: null,
      editId: null,
    }
    this.getAppArticle = this.getAppArticle.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  componentDidMount(){
    this.getAppArticle()
  }

  componentDidUpdate(prevProps){
    if(prevProps.appArticle !== this.props.appArticle){
      this.getAppArticle()
    }
  }

  getAppArticle(){
    if(this.props.appArticle){
      const tableData = this.props.appArticle.map(article => {
        const title = article.title
        const author = article.author
        const timestamp = dayjs(article.timestamp).format("YYYY-MM-DD HH:mm")
        const isShow = article.isShow ? <CheckCircle className="text-success" /> : ''
        const tools = <IconButton onClick={() => this.handleEdit(article.id)}><Edit /></IconButton>
        return [title, author, timestamp, isShow, tools]
      })
      this.setState({ tableData, articles: this.props.appArticle, isWaiting: false })
    }
  }

  handleEdit(editId){
    this.setState({ editId })
  }

  render(){
    if(this.state.editId){
      return <Redirect push to={`/app-article/edit?id=${this.state.editId}`} />
    }

    return (
      <AppList
        isWaiting={this.state.isWaiting}
        articles={this.state.articles}
        tableData={this.state.tableData}
        tableColumns={this.state.tableColumns}
        tableOptions={this.state.tableOptions}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([
    'appArticle' // { path: '/route...' }
  ]),
  connect((state) => {
    return {
      appArticle: state.firestore.ordered.appArticle
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(AppListContainer))