import React from 'react'
import MUIDataTable from "mui-datatables";
import CircularProgress from '@material-ui/core/CircularProgress';

class List extends React.Component {
  render(){
    return (
      <div className="container-fluid sponsor-product-list">
        <div className="wrapper">
          {
            this.props.isWaiting &&
            <CircularProgress />
          }
          {
            !this.props.isWaiting &&
            <MUIDataTable
              title={"標籤列表"}
              data={this.props.tableData}
              columns={this.props.tableColumns}
              options={this.props.tableOptions}
            />
          }
        </div>
      </div>
    )
  }
}

export default (List);