import React from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import Edit from '../../component/SponsorProduct/Edit'
import { TYPE } from '../../config/sponsorProduct'

const queryString = require('query-string')
const isEmpty = require('lodash.isempty')

class EditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: '',
      title: '',
      description: '',
      videoLink: '',
      minPlayingSecond: '',

      coverPhoto: null, // base64
      coverPhotoFile: null, // blob
      coverPhotoFilepath: null, // gs path

      isWaiting: true,
      isWaitingUpdate: false,
      isRedirectToList: false,

      types: TYPE,
      targetId: null,
      targetProduct: null
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getProduct = this.getProduct.bind(this)
    this.setProduct = this.setProduct.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.getCoverPhoto = this.getCoverPhoto.bind(this)
  }

  componentWillMount() {
    const params = this.props.location.search
    const parsed = queryString.parse(params)
    if (parsed.id) {
      this.setState({ targetId: parsed.id }, () => {
        this.getProduct()
      })
    } else {
      this.setState({ isRedirectToList: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sponsorProduct !== this.props.sponsorProduct) {
      const targetProduct = this.props.sponsorProduct.filter(product => product.id === this.state.targetId)[0]
      if (!isEmpty(targetProduct)) {
        this.setState({ targetProduct }, () => {
          this.setProduct()
        })
      } else {
        this.setState({ isRedirectToList: true })
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  getProduct() {
    this.props.firestore.get({ collection: "restrict", doc: this.state.targetId })
  }

  setProduct() {
    const product = this.state.targetProduct
    this.setState({
      title: product.title,
      type: product.type,
      description: product.description,
      videoLink: product.videoLink,
      minPlayingSecond: product.minPlayingSecond,
      isWaiting: false,
    }, () => {
      this.getCoverPhoto(product.coverPhoto)
    })
  }

  async handleSubmit() {
    const product = {
      title: this.state.title,
      description: this.state.description,
      coverPhoto: this.state.coverPhotoFilepath,
      timestamp: +new Date(),
      videoLink: this.state.type === 2 ? this.state.videoLink : null,
      minPlayingSecond: this.state.type === 2 ? this.state.minPlayingSecond : null,
    }

    /** 封面圖片更新過 **/
    if (this.state.coverPhoto && this.state.coverPhoto.indexOf("https") === -1) {
      product.coverPhoto = this.state.coverPhotoFilepath
    }

    this.setState({ isWaitingUpdate: true })
    await this.props.firestore.update({
      collection: 'restrict',
      doc: this.state.targetId,
    }, product)
    this.setState({ isRedirectToList: true })
  }

  handleFileChange(base64, file, fileName) {
    this.setState({ coverPhoto: base64 })
    if (base64) {
      const { firebase: { storage } } = this.props;
      const storageRef = storage().ref()
      const fileRef = storageRef.child(`restrict/coverPhoto/${fileName}`)
      fileRef.put(file)
        .then(snap => {
          this.setState({ coverPhotoFilepath: snap.metadata.fullPath })
          console.log('upload successful', snap)
        })
        .catch(err => {
          console.error('error uploading file', err)
        })
    }
  }

  getCoverPhoto(imagePath) {
    const { firebase: { storage } } = this.props;
    if (imagePath) {
      const storageRef = storage().ref()
      const starsRef = storageRef.child(imagePath);

      // Get the download URL
      starsRef.getDownloadURL().then((url) => {
        this.setState({ coverPhoto: url })
      }).catch((error) => {
        console.error(error)
        alert("取得圖片時發生錯誤")
      });
    }
  }

  render() {
    if (this.state.isRedirectToList) {
      return <Redirect push to="/sponsor-product" />
    }

    return (
      <Edit
        type={this.state.type}
        title={this.state.title}
        description={this.state.description}
        videoLink={this.state.videoLink}
        minPlayingSecond={this.state.minPlayingSecond}
        coverPhoto={this.state.coverPhoto}
        coverPhotoFilepath={this.state.coverPhotoFilepath}
        isWaiting={this.state.isWaiting}
        isWaitingUpdate={this.state.isWaitingUpdate}

        types={this.state.types}

        handleInputChange={this.handleInputChange}
        handleSubmit={this.handleSubmit}
        handleFileChange={this.handleFileChange}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {
      sponsorProduct: state.firestore.ordered.restrict,
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(EditContainer))