import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'

import auth from './reducer/auth'
import tag from './reducer/tag'
import member from './reducer/member'

export default combineReducers({
  firestore: firestoreReducer, // <- needed if using firestore
  auth,
  tag,
  member,
});