import React from 'react'

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import { PlusCircle, Qrcode, Download } from 'mdi-material-ui'
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';

const isEmpty = require("lodash.isempty")
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  progress: {
    color: "white",
  },
  listItem: {
    paddingRight: 6 * theme.spacing.unit,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.handleSubmit()
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root} spacing={16}>
        <Grid item xs={12}>
          <h5>編輯 Coupon</h5>
          <Paper className="paper">
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={16}>
                <Grid item lg={4} xs={12}>
                  <TextField
                    id="title" name="title" required
                    label="優惠券名稱"
                    className={classes.formControl}
                    value={this.props.title}
                    onChange={this.props.handleInputChange}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <TextField
                    id="utmCampaign" name="utmCampaign" required
                    label="UTM Campaign"
                    className={classes.formControl}
                    value={this.props.utmCampaign}
                    margin="normal"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <FormControl fullWidth required className={classes.formControl}>
                    <InputLabel htmlFor="typeSelected">Coupon 類型</InputLabel>
                    <Select
                      value={this.props.typeSelected}
                      onChange={this.props.handleInputChange}
                      inputProps={{
                        name: 'typeSelected',
                        id: 'typeSelected',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {
                        this.props.typeOptions.map((option, index) =>
                          <MenuItem key={`typeOption${index}`} value={option.value}>{option.label}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="originUrl" name="originUrl" required
                    label="Coupon 連結"
                    className={classes.formControl}
                    value={this.props.originUrl}
                    onChange={this.props.handleUTMKeywordChange}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-chip">標籤</InputLabel>
                    <Select
                      multiple
                      name="tagSelected"
                      value={this.props.tagSelected}
                      onChange={this.props.handleInputChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={selected => {
                        return (
                          <div className={classes.chips}>
                            {
                              selected.map(item => (
                                <Chip color="secondary" key={item.value} label={item.label} className={classes.chip}
                                  onDelete={() => this.props.removeTag(item.value)}
                                />
                              ))
                            }
                          </div>
                        )
                      }}
                      MenuProps={MenuProps}
                    >
                      {
                        this.props.tagOptions.map(tag => (
                          <MenuItem key={tag.value} value={tag}>
                            {tag.label}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formControl}>
                    {
                      !isEmpty(this.props.sources) &&
                      <List component="nav">
                        {
                          this.props.sources.map((source, index) => {
                            return (
                              <React.Fragment key={`source${index}`}>
                                <ListItem button disableGutters className={classes.listItem}
                                  onClick={() => this.props.toggleSource(index)}
                                >
                                  <ListItemText primary={`追蹤來源 ${index + 1} - ${source.remark}`} />
                                  {source.isOpen ? <ExpandLess /> : <ExpandMore />}
                                  <ListItemSecondaryAction>
                                    <IconButton onClick={() => this.props.removeSource(index)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                <Collapse in={source.isOpen} timeout="auto" unmountOnExit>
                                  <Grid container spacing={16}>
                                    <Grid item lg={10} xs={12}>
                                      <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                          <TextField
                                            id="remark" name="remark"
                                            label="來源註記"
                                            className={classes.formControl}
                                            value={source.remark}
                                            onChange={(e) => this.props.editSource(index, e)}
                                            margin="normal"
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item lg={3} xs={12}>
                                          <TextField
                                            id="utmSource" name="utmSource" required
                                            label="UTM Source"
                                            className={classes.formControl}
                                            value={source.utmSource}
                                            onChange={(e) => this.props.editSource(index, e)}
                                            margin="normal"
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item lg={3} xs={12}>
                                          <TextField
                                            id="utmMedium" name="utmMedium" required
                                            label="UTM Medium"
                                            className={classes.formControl}
                                            value={source.utmMedium}
                                            onChange={(e) => this.props.editSource(index, e)}
                                            margin="normal"
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item lg={3} xs={12}>
                                          <TextField
                                            id="utmTerm" name="utmTerm"
                                            label="UTM Term"
                                            className={classes.formControl}
                                            value={source.utmTerm}
                                            onChange={(e) => this.props.editSource(index, e)}
                                            margin="normal"
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item lg={3} xs={12}>
                                          <TextField
                                            id="utmContent" name="utmContent"
                                            label="UTM Content"
                                            className={classes.formControl}
                                            value={source.utmContent}
                                            onChange={(e) => this.props.editSource(index, e)}
                                            margin="normal"
                                            fullWidth
                                          />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <TextField
                                            id="finalUrl" name="finalUrl"
                                            label="轉址連結"
                                            className={classes.formControl}
                                            value={source.finalUrl}
                                            margin="normal"
                                            fullWidth
                                            InputProps={{ readOnly: true, }}
                                          />
                                        </Grid>

                                      </Grid>
                                    </Grid>
                                    <Grid item lg={2} xs={12} style={{ margin: `auto 0`, textAlign: `center` }}>
                                      {
                                        !source.isShowQRCode &&
                                        <Button color="secondary" className={classes.button} onClick={() => this.props.getQRCode(index)}>
                                          產生 QR Code
                                          <Qrcode className={classes.rightIcon} />
                                        </Button>
                                      }
                                      {
                                        source.isShowQRCode &&
                                        <div>
                                          <Button color="secondary" className={classes.button} style={{ marginBottom: `-15px` }}
                                            download target="_blank" rel="noopener noreferrer"
                                            href={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${encodeURIComponent(source.finalUrl)}`}
                                          >
                                            下載<Download className={classes.rightIcon} />
                                          </Button>
                                          <img alt="QRCOde" className="img-fluid"
                                            src={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${encodeURIComponent(source.finalUrl)}`}
                                          />
                                        </div>
                                      }
                                    </Grid>
                                  </Grid>
                                </Collapse>
                              </React.Fragment>
                            )
                          })
                        }
                      </List>
                    }
                    <div className="">
                      <Button color="primary" className={classes.button} onClick={this.props.addSource}>
                        新增來源
                        <PlusCircle className={classes.rightIcon} />
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.formControl}>
                    <Button type="submit" variant="contained" color="primary" className={classes.button}
                      disabled={this.props.isWaitingSubmit}
                    >
                      {!this.props.isWaitingSubmit && "更新"}
                      {this.props.isWaitingSubmit && <CircularProgress className={classes.progress} size={20} />}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid >
    )
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Edit);