import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    marginBottom: 5,
    width: 60,
    height: 60,
  },
  chip: {
    margin: 5,
  },
})

const tags = [
  { name: "KKBOX 2 年", count: 1 },
  { name: "參賽者", count: 7 },
  { name: "歌唱類型賽事", count: 3 },
  { name: "演奏類型賽事", count: 2 },
  { name: "創作類型賽事", count: 2 },
  { name: "吉他學習 3-5 年", count: 1 },
  { name: "歌唱學習 1-2 年", count: 1 },
]

class MA extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-5">
            <Paper className="d-flex p-2">
              <section>
                <Avatar alt="Remy Sharp" src="https://instagram.ftpe8-3.fna.fbcdn.net/vp/2684b922f3009842eaba2e98f289099e/5E571A57/t51.2885-19/s320x320/18645853_378695982526431_1188873919927418880_a.jpg?_nc_ht=instagram.ftpe8-3.fna.fbcdn.net" className={classes.bigAvatar} />
                <small className="d-block text-center">陳旭易</small>
              </section>
              <section className="d-block my-auto ml-1">
                {
                  tags.map((tag, index) => {
                    const { name, count } = tag
                    const label = count !== 1 ? <div>{name}<span className="badge badge-light">{count}</span></div> : name
                    return <Chip key={tag.name} className={classes.chip} label={label} variant="outlined"
                      color="primary"
                    />
                  })
                }
              </section>
            </Paper>
          </div>
        </div>
      </div>
    )
  }
}

MA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MA);