import {
  SET_AUTH_STATUS,
} from '../action/auth'

const initialState = {
  isAuth: null,
}

export default(state = initialState, action) => {
  switch(action.type){
    case SET_AUTH_STATUS:
      return {
        ...state,
        isAuth: action.isAuth
      }
    default:
      return state
  }
}