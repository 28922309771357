import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import Insert from '../../component/Form/Insert'
import { GROUP } from '../../config/environment'
import { receiveMembers } from '../../action/member'
import agent from '../../agent/agent'

const cloneDeep = require("lodash.clonedeep")
const isEmpty = require("lodash.isempty")

class InsertContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isWaiting: false,
      isWaitingSubmit: false,

      targetSchool: null,
      schools: null,
      groups: [],

      targetMember: null,
      members: null,
      membersTableData: null,

      lineId: '',
      schoolId: '',
      name: '',
      email: '',
      school: '',
      group: '',
      song: '',
      partners: [""],
      last5Code: '',

      mic: 0,
      micStand: 0,
      guitar: 0,
      bass: 0,
      cajon: 0,
      kb: 0,
      chair: 0,
      effector: '',
      remark: '',
      numOfPartner: 0,
      commonField: [],
      customField: [],

      micMax: 0,
      micStandMax: 0,
      guitarMax: 0,
      bassMax: 0,
      cajonMax: 0,
      kbMax: 0,
      isEffector: false,

      isMemberModalOpen: false,
    }
    this.getMember = this.getMember.bind(this)
    this.setMember = this.setMember.bind(this)
    this.setPartnerByGroupChange = this.setPartnerByGroupChange.bind(this)
    this.setTargetMember = this.setTargetMember.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleGroupChange = this.handleGroupChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleTargetSchoolChange = this.handleTargetSchoolChange.bind(this)
    this.handleCommonFieldChange = this.handleCommonFieldChange.bind(this)
    this.handleCustomFieldChange = this.handleCustomFieldChange.bind(this)

    this.addMember = this.addMember.bind(this)
    this.removeMember = this.removeMember.bind(this)
    this.handleMemberChange = this.handleMemberChange.bind(this)

    this.setModalState = this.setModalState.bind(this)
  }

  componentWillMount() {
    this.getMember()
  }

  componentDidMount() {
    this.setMember()
  }

  componentDidUpdate(prevProps, prevState) {
    /** firestore members 資料第一次進來，dipatch 至 reducer */
    if (prevProps.firestoreMembers !== this.props.firestoreMembers) {
      this.props.receiveMembers(this.props.firestoreMembers)
    }

    /** 將 reducer member 資料轉換為 react-select 格式 */
    if (prevProps.members !== this.props.members) {
      this.setMember()
    }
  }

  getMember() {
    const { members } = this.props
    if (!members || members.length === 1) {
      this.props.firestore.get({ collection: 'member', where: ['phone', '>', ''] })
    }
  }

  setMember() {
    if (this.props.members) {
      const tableData = this.props.members.map(member => {
        const { id, name, phone, email, school } = member
        const schoolLevel = school.level
        const schoolName = school.name
        let schoolLevelName = ''
        switch (schoolLevel) {
          case 1:
          case '1': schoolLevelName = "高中制"; break
          case 2:
          case '2': schoolLevelName = "大學制"; break
          case 3:
          case '3': schoolLevelName = "社會人士"; break
          default: break
        }
        return [id, name, phone, email, schoolLevelName, schoolName]
      })
      this.setState({ membersTableData: tableData })
    }
  }

  setTargetMember(lineId, name, email, schoolName) {
    this.setState({
      lineId, name, email,
      school: schoolName,
      isMemberModalOpen: false,
    })
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleTargetSchoolChange(event) {
    const schoolId = event.target.value
    const groups = []
    const targetSchool = this.props.schools.find(item => item.id === schoolId)
    for (const key in targetSchool.groupConfig) {
      const label = GROUP[key]
      groups.push({ label, value: key })
    }
    this.setState({
      targetSchool,
      groups,
      group: '',
      micMax: 0, micStand: 0, guitarMax: 0, bassMax: 0, cajonMax: 0, kbMax: 0, isEffector: false,  // init equipment limit
      partners: [""],
      commonField: targetSchool.commonField.map(item => {
        const isMultiLine = !!item.isMultiLine
        return ({ ...item, isMultiLine, value: '' })
      })
    })
  }

  handleGroupChange(event) {
    const value = event.target.value
    const partners = this.setPartnerByGroupChange(value)
    const { targetSchool } = this.state
    const { mic, micStand, guitar, bass, cajon, KB, isEffector, numOfPartner, customField } = targetSchool.groupConfig[value]
    this.setState({
      [event.target.name]: value,
      micMax: mic.max,
      micStandMax: micStand.max,
      guitarMax: guitar.max,
      bassMax: bass.max,
      cajonMax: cajon.max,
      kbMax: KB.max,
      isEffector,
      numOfPartner,
      partners,
      customField: customField.map(item => {
        const { isMultiLine } = item
        return ({ ...item, isMultiLine, value: '' })
      }),
    })
  }

  setPartnerByGroupChange(group) {
    const { partners, targetSchool } = this.state
    if (partners.length > targetSchool.groupConfig[group].numOfPartner) {
      const newMembers = partners.slice(0, this.state.targetSchool.groupConfig[group].numOfPartner)
      return newMembers.length === 0 ? [""] : newMembers
    } else {
      return partners
    }
  }

  async handleSubmit() {
    if (window.confirm("確定新增嗎?")) {
      this.setState({ isWaitingSubmit: true })

      const {
        lineId, targetSchool, song, group, partners, last5Code,
        mic, micStand, guitar, bass, cajon, kb, chair, remark, effector,
        commonField, customField,
      } = this.state

      const body = {
        lineId,
        song,
        group: Number(group),
        organizer: targetSchool.id,
        code: last5Code,
        partners: [],
        equipment: {
          mic, micStand, guitar, bass, cajon, chair, effector,
          other: remark,
          KB: kb,
        },
        commonField: !isEmpty(commonField) ? commonField : [],
        customField: !isEmpty(customField) ? customField : [],
      }

      /** 防止全空字串 */
      for (const member of partners) {
        if (member.trim().length !== 0) {
          body.partners.push(member)
        }
      }

      const result = await agent.Form.create(body).catch(error => {
        console.error(error)
      })

      if (result.status !== 200) alert("新增失敗！")

      this.setState({
        isWaitingSubmit: false,
        isSuccessRedirect: result.status === 200
      })
    }
  }

  addMember() {
    const partners = cloneDeep(this.state.partners)
    partners.push("")
    this.setState({ partners })
  }

  removeMember(index) {
    const partners = this.state.partners
    if (partners.length !== 1) {
      partners.splice(index, 1)
    } else {
      partners[index] = ""
    }
    this.setState({ partners })
  }

  handleMemberChange(event, index) {
    const partners = cloneDeep(this.state.partners)
    partners[index] = event.target.value
    this.setState({ partners })
  }

  handleCommonFieldChange(event, index) {
    const commonField = this.state.commonField
    commonField[index][`value`] = event.target.value
    this.setState({ commonField })
  }

  handleCustomFieldChange(event, index) {
    const customField = this.state.customField
    customField[index][`value`] = event.target.value
    this.setState({ customField })
  }

  setModalState(modal, state) {
    this.setState({ [modal]: state })
  }

  render() {
    if (this.state.isSuccessRedirect) {
      return <Redirect push to="/form/insert/success" />
    }

    return (
      <Insert
        schools={this.props.schools}
        members={this.state.members}
        groups={this.state.groups}
        targetSchool={this.state.targetSchool}
        targetMember={this.state.targetMember}
        membersTableData={this.state.membersTableData}

        lineId={this.state.lineId}
        name={this.state.name}
        email={this.state.email}
        school={this.state.school}
        group={this.state.group}
        song={this.state.song}
        partners={this.state.partners}
        remark={this.state.remark}
        numOfPartner={this.state.numOfPartner}
        isEffector={this.state.isEffector}
        commonField={this.state.commonField}
        customField={this.state.customField}
        last5Code={this.state.last5Code}

        mic={this.state.mic}
        micStand={this.state.micStand}
        guitar={this.state.guitar}
        bass={this.state.bass}
        cajon={this.state.cajon}
        kb={this.state.kb}
        chair={this.state.chair}
        effector={this.state.effector}

        micMax={this.state.micMax}
        micStandMax={this.state.micStandMax}
        guitarMax={this.state.guitarMax}
        bassMax={this.state.bassMax}
        cajonMax={this.state.cajonMax}
        kbMax={this.state.kbMax}

        addMember={this.addMember}
        removeMember={this.removeMember}
        handleMemberChange={this.handleMemberChange}
        setTargetMember={this.setTargetMember}

        isMemberModalOpen={this.state.isMemberModalOpen}
        setModalState={this.setModalState}

        isWaiting={this.state.isWaiting}
        isWaitingSubmit={this.state.isWaitingSubmit}
        handleInputChange={this.handleInputChange}
        handleTargetSchoolChange={this.handleTargetSchoolChange}
        handleGroupChange={this.handleGroupChange}
        handleCommonFieldChange={this.handleCommonFieldChange}
        handleCustomFieldChange={this.handleCustomFieldChange}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = state => ({
  members: state.member.members,
})

const mapDispatchToProps = dispatch => ({
  receiveMembers: (members) => dispatch(receiveMembers(members))
})

export default compose(
  firestoreConnect([
    'school' // { path: '/route...' }
  ]),
  connect((state) => {
    return {
      schools: state.firestore.ordered.school,
      firestoreMembers: state.firestore.ordered.member,
    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(InsertContainer))