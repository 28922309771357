import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { Redirect } from 'react-router-dom'

import New from '../../component/SponsorProduct/New'
import { TYPE } from '../../config/sponsorProduct'

class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: '',
      title: '',
      description: '',
      videoLink: '',
      minPlayingSecond: '',
      isWaitingSubmit: false,
      isRedirectToList: false,

      coverPhoto: null, // base64
      coverPhotoFile: null, // blob
      coverPhotoFilepath: null, // gs path

      types: TYPE,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleFileChange(base64, file, fileName) {
    this.setState({ coverPhoto: base64 })
    if (base64) {
      const { firebase: { storage } } = this.props;
      const storageRef = storage().ref()
      const fileRef = storageRef.child(`restrict/coverPhoto/${fileName}`)
      fileRef.put(file)
        .then(snap => {
          this.setState({ coverPhotoFilepath: snap.metadata.fullPath })
          console.log('upload successful', snap)
        })
        .catch(err => console.error('error uploading file', err))

    }
  }

  async handleSubmit() {
    const sponsorProduct = {
      type: this.state.type,
      title: this.state.title,
      description: this.state.description,
      coverPhoto: this.state.coverPhotoFilepath,
      timestamp: +new Date(),
      videoLink: this.state.type === 2 ? this.state.videoLink : null,
      minPlayingSecond: this.state.type === 2 ? this.state.minPlayingSecond : null,
    }
    this.setState({ isWaitingSubmit: true })
    try {
      console.log(sponsorProduct)
      await this.props.firestore.add('restrict', sponsorProduct)
      this.setState({ isRedirectToList: true })
    } catch (err) {
      this.setState({ isWaitingSubmit: false })
      console.error(err)
      alert("新增失敗！")
    }
  }

  render() {
    if (this.state.isRedirectToList) {
      return <Redirect push to="/sponsor-product" />
    }
    return (
      <New
        title={this.state.title}
        type={this.state.type}
        description={this.state.description}
        videoLink={this.state.videoLink}
        minPlayingSecond={this.state.minPlayingSecond}
        coverPhoto={this.state.coverPhoto}
        coverPhotoFilepath={this.state.coverPhotoFilepath}
        isWaitingSubmit={this.state.isWaitingSubmit}

        types={this.state.types}

        handleInputChange={this.handleInputChange}
        handleFileChange={this.handleFileChange}
        handleSubmit={this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({

})

export default compose(
  firestoreConnect([

  ]),
  connect((state) => {
    return {

    }
  })
)(connect(mapStateToProps, mapDispatchToProps)(NewContainer))